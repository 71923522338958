import React, { useState, useEffect } from 'react';
import showAlert from '../../../server/ShowAlert';
import AddStationToSale from './AddStationToSale'
import Input from '../../containers/Input';
import { GetStationList } from '../../../server/SalesManagement'
import { setPreload } from '../../../server/CartsOpen';
import { useDispatch } from 'react-redux'
import { UpdateStationToSalePost } from '../../../server/SalesManagement'

const StationsInSale = ({ sale, checkedDates }) => {
    const dispatch = useDispatch();
    const [station, setStation] = useState(false)
    const [stationList, setStationList] = useState([])

    const changeQuantity = (value, id) => {
        const list = [...stationList]
        list.find(x => x.Id == id).Quantity = value
        list.find(x => x.Id == id).quantityChanged = true

        setStationList(list)
    }

    useEffect(() => {
        getStationList()
    }, [])

    const getStationList = () => {
        dispatch(setPreload(true));
        GetStationList(sale.Id)
            .then(x => {
                if (x.data) {
                    setStationList(x.data)
                }
            })
            .catch(() => showAlert({}))
            .finally(() => dispatch(setPreload(false)));
    }
    const updateStation = (data, add) => {
        if (stationList.some(item => item.StationId == data.StationId) && !data.quantityChanged && add) {
            showAlert({ msgTitle: "מחסן זה כבר נמצא במבצע", msgType: "error" })
            setStation(false)
            return;
        }
        data["SaleId"] = sale.Id;
        data["Add"] = add;
        if (checkedDates(sale.FromDate, sale.ToDate)) {
            UpdateStationToSalePost(data)
                .then(x => {
                    const stationArr = [...stationList]
                    if (x.data && add) {
                        if (data.quantityChanged) {
                            showAlert({ msgTitle: 'המחסן התעדכן למבצע בהצלחה', msgType: 'success' })
                            stationArr.map(el => (
                                el.Id === x.data.Id ? { ...el, Quatity: x.data.Quatity } : el
                            ))
                        }
                        else {
                            showAlert({ msgTitle: 'המחסן התווסף למבצע בהצלחה', msgType: 'success' })
                            stationArr.push(x.data)
                        }
                        setStationList(stationArr)
                        setStation(false)
                    }
                    if (x.data && !add) {

                        const removeItem = stationArr.filter(item => item.Id !== x.data.Id)
                        setStationList(removeItem)
                        showAlert({ msgTitle: 'המחסן נמחק מהמבצע בהצלחה ', msgType: 'success' })

                    }
                })
                .catch((x) => {
                    if (x.status == 400) {
                        showAlert({ msgTitle: x.data })
                        setStation(false)
                    }
                    else {
                        showAlert({})
                        setStation(false)
                    }
                })
                .finally(() => dispatch(setPreload(false)));
        }
        else {
            showAlert({ msgTitle: "המבצע כבר התחיל, לא ניתן לשנות הגדרות", msgType: 'success' })
            return
        }
    }
    return (
        <div className="show-sales-table">
            {station ? <AddStationToSale updateStation={updateStation} />
                : <button className='add-station' onClick={() => setStation(true)}>הוספת תחנה למבצע</button>
            }
            {stationList.length ? <table>
                <tbody>
                    <tr>
                        <th>מזהה מחסן </th>
                        <th>כמות למכירה</th>
                        <th>כמות מימושים</th>
                        <th>מחיקת מחסן</th>
                        <th>עדכון מחסן</th>
                    </tr>
                    {stationList.map(station => <tr key={station.Id}>
                        <td>
                            {station.StationId}
                        </td>
                        <td>
                            <Input
                                Type="number"
                                Value={station.Quantity}
                                ClassName="search-input"
                                InputChange={(value) => changeQuantity(value, station.Id)}
                            />
                        </td>
                        <td>
                            {station.QuantityUsed}
                        </td>
                        <td>
                            <button onClick={() => updateStation(station, false)}>מחק</button>
                        </td>
                        <td>
                            <button disabled={!station.quantityChanged} onClick={() => updateStation(station, true)}>עדכן</button>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table > : <h2>אין רשימת מחסנים</h2>}
        </div>
    )
}

export default StationsInSale