import React from 'react';
import Contacts from '../contacts/Contacts';
import CategoryShow from '../categoryShow/CategoryShow';
import OpenText from '../../OpenText';


const Home = () => {

  const css = `.tei-icon { display: block !important; }`;





  return (
    <div className="home-page">
      {/* <style>{css}</style> */}
      <OpenText  ShowHome={true}/>
      {/* <CategoryShow /> */}
      {/* <Contacts home={true} /> */}
    </div>
  )
}


export default Home