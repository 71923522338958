import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action';

export const CollectSiteProductGet = () => {
    return dispatch => {
        axios.get(`${url}CollectSite/GetCollectSiteProduct`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }).then(x => {
            if (x.data) {
                dispatch({ type: actionType.SET_ALL_PRODUCT, payload: x.data })
            }
        })
    }
}

export const CollectSiteProductSetOrder = (orderId) => {
    return dispatch => {
        axios.get(`${url}CollectSite/CollectSiteProductSetOrder?orderId=${orderId}`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }).then(x => {
            if (x.data) {
                dispatch({ type: actionType.SET_ALL_PRODUCT, payload: x.data })
            }
        })
    }
}

export const CollectSiteProductSetProduct = (product, Quantity) => {
    return (dispatch) => {
        const ProductMkt = product.ProductMkt ? product.ProductMkt : product.Mkt;
        if (ProductMkt > 100) {
            const val = {
                ProductMkt,
                Quantity,
                Price: product.Price,
            };

            axios.post(`${url}CollectSite/SetProduct`, val, {
                headers: {
                    'Authorization': `bearer ${localStorage.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
                .then(x => {
                    if (x.data.ReturnNew) {
                        dispatch({ type: actionType.SET_ALL_PRODUCT, payload: x.data })
                    }
                    else {
                        dispatch({ type: actionType.ADD_PRODUCT_IN_CART, payload: { data: x.data, Quantity, product } })
                    }
                })
                .catch (() => console.log("error"))
        }
    }
}

export const CancelError = () => {
    return dispatch => {
        dispatch({ type: actionType.CANCEL_ERROR })
    }
}

export const clearCart = () => {
    return dispatch => {
        dispatch({ type: actionType.CLEAR_CARD })
    }
}