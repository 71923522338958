import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { globalFileIcon } from '../../../axiosInstance';
import { useDispatch, useSelector } from "react-redux";
import { setToggleSidebar } from '../../server/CartsOpen'

const sidebarAdmin = [
	{ Title: 'מחלקות', Link: '/category-edit', Img: 'list.svg' },
	{ Title: 'מסנני מוצרים', Link: '/filters', Img: 'filter.svg' },
	{ Title: 'הזמנות', Link: '/admin-history', Img: 'notifications.svg' },
	{ Title: 'זמני משלוח', Link: '/delivery-time-edit', Img: 'notifications.svg' },
	{
		Title: 'תמונות', Button: 'images', Img: 'notifications.svg', children: [

			{ Title: 'הוספת תמונה', Link: '/addImg', Img: 'clients.svg' },
			{ Title: 'תמונות חדשות', Link: '/new-image', Img: 'notifications.svg' },
			{ Title: 'פרסומת', Link: '/Advertising', Img: 'clients.svg' },
		]
	},
	{
		Title: 'מחסן', Button: 'station', Img: 'notifications.svg', children: [

			{ Title: 'עדכון מלאי', Link: '/inventory-update', Img: 'notifications.svg' },
			{ Title: 'מחסן-פרטי משלוח', Link: '/station-info', Img: 'notifications.svg' },
			{ Title: 'מחסן - הוספה', Link: '/station', Img: 'notifications.svg' },
			{ Title: 'הגדרת מיקום', Link: '/station-location', Img: 'notifications.svg' },
		]
	},
	{ Title: 'ניהול מבצעים', Link: '/sales-management', Img: 'notifications.svg' },
	{ Title: 'לקוחות', Link: '/users', Img: 'clients.svg' },
]

const Nav = () => {
	const dispatch = useDispatch();
	const { role, toggleSidebar } = useSelector(state => ({
		role: state.user.role,
		toggleSidebar: state.openCart.toggleSidebar
	}));
	const [subMenu, setSubMenu] = useState("")



	return (
		role ? <nav className={toggleSidebar ? 'active sidebar' : 'sidebar'}>
			<h2>פאנל הניהול</h2>
			<ul>
				{sidebarAdmin.map((element, index) =>
					<li key={index} >
						{!element.children ?
							<NavLink to={element.Link} onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}>
								<img loading="lazy" src={globalFileIcon + 'nav/' + element.Img} alt={element.Title} />
								<span>{element.Title}</span>
								<div className="img">
									<img loading="lazy" src={globalFileIcon + 'back.svg'} alt="back" />
								</div>

							</NavLink>
							:
							element.children.length ?
								<Fragment>

									<button className="btn-head" onClick={() => setSubMenu(element.Button)}>
										<img loading="lazy" src={globalFileIcon + 'nav/' + element.Img} alt={element.Title} />
										<span>{element.Title}</span>
										<div className="img">
											<img loading="lazy" src={globalFileIcon + 'back.svg'} alt="back" />
										</div>
									</button>
									{subMenu === element.Button ?
										element.children.map(subelement =>
											<NavLink className='sub-menu' key={subelement.Link} to={subelement.Link} onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}>
												<img loading="lazy" src={globalFileIcon + 'nav/' + subelement.Img} alt={subelement.Title} />
												<span>{subelement.Title}</span>
												<div className="img">
													<img loading="lazy" src={globalFileIcon + 'back.svg'} alt="back" />
												</div>
											</NavLink>
										)
										: null}
								</Fragment>
								: null}
					</li>
				)}
			</ul>
		</nav> : null
	)
}

export default Nav;
