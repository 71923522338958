import { globalFileServer } from "../../../../axiosInstance";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShowCategory from "./showCategory";

const CategoriesEdit = () => {
  const [search, setSearch] = useState("");
  const { categoriesState } = useSelector((state) => ({
    categoriesState: state.category.categories,
  }));

  const searchFun = (e) => {
    let search = e.target.value;
    setSearch(search);
  };

  const clearSearch = () => {
    setSearch("");
  };

  return (
    <div className="category-edit">
      <h1 className="title-role">ניהול מחלקות</h1>
      <div className="items-container">
        <div className="flex-container">
          <div className="search">
            <input
              onChange={searchFun}
              value={search}
              type="text"
              placeholder="חיפוש..."
            />
            {search ? (
              <img
                loading="lazy"
                className="close"
                onClick={clearSearch}
                src={globalFileServer + "iconcss/close.svg"}
                alt="close"
              />
            ) : (
              <img
                loading="lazy"
                src={globalFileServer + "iconcss/search.svg"}
                alt="search"
              />
            )}
          </div>
        </div>
      </div>
      <ShowCategory />
    </div>
  );
};

export default CategoriesEdit;
