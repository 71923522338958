import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action'
import showAlert from './ShowAlert';
import { setPreload } from '../server/CartsOpen';

export const reloedFunc = () => dispatch => dispatch({ type: actionType.RELOED })

export const getSales = (stationId) => {
    return dispatch => {
        axios.get(`${url}sales?stationId=${stationId}`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(x => {
            dispatch({
                type: actionType.SET_PRODUCTS,
                payload: {
                    items: x.data
                    //  x.data.sort((a, b) => { return a.CategoryFullOrderNumber - b.CategoryFullOrderNumber }),
                    //                    items: x.data.sort((a, b) => { return a.orderNumber - b.orderNumber }).sort((a, b) => { return a.CategoryOrderNumber - b.CategoryOrderNumber }).sort((a, b) => { return a.CategoryFather1OrderNumber - b.CategoryFather1OrderNumber }),      
                }
            });

        })
            .catch(() => console.log("error"));
    }
}

export const getByMkt = (mkt) => {
    return dispatch => {
        axios.get(`${url}Product?Mkt=${mkt}`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then(x => dispatch({ type: actionType.SET_SELCTED_PRODUCT, data: x.data }))
            .catch(() => console.log("error"));

    }
}

export const getItemsPerCategoryCount = (id, stationId, count, mkt) => {
    return axios.get(`${url}getItemsPerCategoryCount?id=${id}&stationId=${stationId}&mkt=${mkt}&count=${count}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        }
    })
}
export const getItemsPerCategory = (id, stationId) => {
    return axios.get(`${url}getItemsPerCategory?id=${id}&stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        }
    })
}
export const getItemsPerCategoryDispatch = (id, stationId) => {
    return dispatch => {
        getItemsPerCategory(id, stationId)
            .then(x => {
                const items = x.data;
                items.sort((a, b) => a.CategoryFullOrderNumber - b.CategoryFullOrderNumber);
                dispatch({
                    type: actionType.SET_PRODUCTS,
                    payload: {
                        items
                        //: x.data.sort((a, b) => { return a.CategoryFullOrderNumber - b.CategoryFullOrderNumber })
                    }
                });
            })
            .catch(() => console.log("error"));
    }
}


export const getItemsPerWord = (word, stationId) => {
    return dispatch => {
        axios.get(`${url}getItemsPerWord?Word=${word}&stationId=${stationId}`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        }).then(x => {
            dispatch({
                type: actionType.SET_PRODUCTS,
                payload: {
                    items: x.data,
                }
            });

        })
            .catch(() => console.log("error"));
    }

}

export const UpdateSort = (data) => {
    return dispatch => {
        axios.post(`${url}UpdateSort`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then(x => dispatch({ type: actionType.SORT_PRODUCTS, payload: { orderNumber: x.data, Mkt: data.MktChange } }))
            .catch(() => { showAlert({ msgTitle: "הייתה בעיה בהחלפת המיקומים", msgType: "error" }); });
    }

}

export const UpdateProduct = (data) => {
    return axios.post(`${url}UpdateProduct`,
        data,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}

export const AddProduct = (CategoryId) => {
    return dispatch => {
        dispatch(setPreload(true));
        axios.post(`${url}product?CategoryId=${CategoryId}`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.token}`,
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(x => dispatch({ type: actionType.ADD_NEW_PRODUCT, payload: x.data }))
            .catch(() => console.log("error"))
            .finally(() => dispatch(setPreload(false)));
    }
}

export const GetProductEditMini = (mkt) => {
    return axios.get(`${url}Product/GetProductEditMini?mkt=${mkt}`,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}

export const SetImgActive = (Mkt, imgDataId, imgName) => {
    return dispatch => axios.get(`${url}Product/SetImgActive?mkt=${Mkt}&ImgId=${imgDataId}`,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
        .then(x => dispatch({ type: actionType.SET_UPDAATE_SELCED_PRODUCT, imgName }))
}

export const GetImgUrlProduct = (mkt) => {
    return axios.get(`${url}product/GetImgUrlProduct?mkt=${mkt}`,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}

export const GetProducts = () => {
    return dispatch => axios.get(`${url}product`,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        }).then(x => dispatch({ type: actionType.SET_ALL_SELECTED_PRODUCT, data: x.data }))
}

export const toggleFilter = (id, typeName) => {
    return dispatch => {
        dispatch({ type: actionType.TOGGLE_FILTER, payload: { id, typeName } })
    }
}

export const clearFilters = () => {
    return dispatch => {
        dispatch({ type: actionType.CLEAR_FILTER })
    }
}

export const clearSearch = () => {
    return dispatch => {
        dispatch({ type: actionType.DELETE_SEARCH })
    }
}

export const search = (word) => {
    return dispatch => {
        dispatch({ type: actionType.SET_SEARCH_PRODUCT, payload: word })
    }
}

export const setFilterSort = (bool) => {
    return dispatch => {
        dispatch({ type: actionType.SET_FILTER_SORT, payload: bool })
    }
}