import React, { useState } from 'react'
import showAlert from '../../server/ShowAlert';
import PopUp from '../containers/PopUp';
import AddressShipping from '../../components/addressShipping/AddressShipping';
import { loginToken, sendVerifiedMail } from '../../server/Customer';
import { useDispatch } from 'react-redux';
import * as actionType from '../../store/action'


const UserResetEmail = ({ emailPassword, Email, Password, customerId }) => {
    const [password, setPassword] = useState('');
    const [addAddress, setAddAddress] = useState(false);
    const dispatch = useDispatch();
    const passwordOk = () => {
        const val = {
            'userName': Email,
            'password': Password,
            "grant_type": "password"
        }
        dispatch(loginToken(val, true))

    }

    const check = () => {
        if (password === atob(emailPassword)) {
            sendVerifiedMail(customerId).then(x => {
                passwordOk()
                setAddAddress(true)
            })
                .catch(x => {
                    showAlert({ msgTitle: "לא הצלחנו לאמת את המייל", msgType: "error" })
                })
        }
        else {
            showAlert({ msgTitle: "הסיסמה לא מתאימה", msgType: "error" })
        }
    }

    return (
        <PopUp width='700px' close={() => dispatch({ type: actionType.VERIFIED_EMAIL })}>
            {addAddress ? <AddressShipping parent='reset' setSelectedAddres={() => dispatch({ type: actionType.VERIFIED_EMAIL })} /> :
                <div className="register-mail">
                    <div className="register-head">
                        <h3> ההרשמה כמעט הושלמה, עכשיו רק נשאר לאמת את המייל</h3>
                    </div>
                    <div className='register-body'>
                        <p>יש להכניס את הסיסמה שקיבלתם למייל</p>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button className='btn' onClick={check}>לאישור</button>
                    </div>
                </div>
            }
        </PopUp>
    )
}

export default UserResetEmail
