import React from 'react';
import Input from '../../containers/InputForm';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
const schema = yup.object({
    Cell: yup.string().required().max(3,"עד שלוש ספרות").matches(/^\d+$/,"","","מספר בלבד"),
    Row: yup.string().required().max(3,"עד שלוש ספרות").matches(/^\d+$/,"","","מספר בלבד"),
    Colmon: yup.string().required("שדה חובה").max(3,"עד שלוש ספרות").matches(/^\d+$/,"","","מספר בלבד")

}).required();
const PlaceInsert = ({ CheckAllDataInInput }) => {

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) })


    const CheckAllDataIn = (data) => {
        CheckAllDataInInput(data)

    }
    return (
        <form className='place-insert' onSubmit={handleSubmit(CheckAllDataIn)} >
                <Input
                    errors={errors}
                    register={register}
                    name="Colmon"
                    label="טור"
                    Type="number"
                />
                <Input
                    errors={errors}
                    register={register}
                    label="ארגז"
                    Type="number"
                    name="Cell"
                />
                <Input
                    errors={errors}
                    register={register}
                    label="קומה"
                    Type="number"
                    name="Row"
                />
           <button type='submit' className='btn'>הוסף   </button>
        </form >
    )
}

export default PlaceInsert
