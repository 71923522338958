import moment from 'moment'

const arrHebrewDay = ["א","ב","ג","ד","ה","ו","ז","ח","ט","י","י\"א","י\"ב","י\"ג","י\"ד","ט\"ו","ט\"ז","י\"ז","י\"ח","י\"ט","כ","כ\"א","כ\"ב","כ\"ג","כ\"ד","כ\"ה","כ\"ו","כ\"ז","כ\"ח","כ\"ט","ל"]

export default  (dateTime) => {
    return moment(new Date(dateTime)).format('yyyy-MM-dd')
}
//TimeYearMonthDate;

export const TimeDateMonth = (dateTime) => {
    return moment(new Date(dateTime)).format('dd/MM')
}

export const TimeDateMonthYear = (dateTime) => {
    return moment(new Date(dateTime)).format('dd/MM/yyyy')
}

export const TimeDateMonthSmallYear = (dateTime) => {
    return moment(new Date(dateTime)).format('dd/MM/yy')
}
export const DateHourMinute = (dateTime) => {
    return moment(new Date(dateTime)).format('HH:mm')
}

export const HebrewDate = (dateTime) => {
    const hebrewDay = arrHebrewDay[new Intl.DateTimeFormat('he-u-ca-hebrew',{ day:'numeric'}).format(dateTime)-1]   
    const hebrewMonth =  new Intl.DateTimeFormat('he-u-ca-hebrew',{month:'numeric'}).format(dateTime)
    const hebrewDate = hebrewDay +" "+ hebrewMonth
    return hebrewDate
}
