import React, { useState, useEffect } from 'react';
import AddProductToSale from './AddProductToSale'
import { GetProductList, GetStationList } from '../../../server/SalesManagement'
import { setPreload } from '../../../server/CartsOpen';
import showAlert from '../../../server/ShowAlert'
import { useDispatch } from 'react-redux'
import { UpdateProductToSalePost } from '../../../server/SalesManagement'


const ProductInSale = ({ sale, checkedDates }) => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState(false)
    const [productList, setProductList] = useState([])

    useEffect(() => {
        getProductList()
    }, [])

    const getProductList = () => {
        dispatch(setPreload(true));
        GetProductList(sale.Id)
            .then(x => {
                if (x.data) {
                    setProductList(x.data)
                }
            })
            .catch(() => showAlert({}))
            .finally(() => dispatch(setPreload(false)));
    }

    const updateProduct = (ProductMkt, Add) => {
        const data = {
            SaleId: sale.Id,
            ProductMkt,
            Add
        }
        if (productList.some(item => item.ProductMkt == data.ProductMkt) && Add) {
            showAlert({ msgTitle: "המוצר כבר נמצא במבצע", msgType: "error" })
            return;
        }
        if (checkedDates(sale.FromDate, sale.ToDate)) {
            UpdateProductToSalePost(data)
                .then(x => {
                    const productArr = [...productList]
                    if (x.data && Add) {
                        if (!x.data[0].Id) {
                            showAlert({ msgTitle: x.data[0].Massage })
                            return
                        }
                        productArr.push(x.data[0])
                        setProductList(productArr)
                        setProduct(false)
                        showAlert({ msgTitle: 'המוצר התווסף בהצלחה ', msgType: 'success' })


                    }
                    else if (!Add) {
                        const removeItem = productArr.filter(item => item.ProductMkt !== ProductMkt)
                        setProductList(removeItem)
                        showAlert({ msgTitle: 'המוצר נמחק מהמבצע בהצלחה ', msgType: 'success' })
                    }
                })
                .catch((x) => {
                    if (x.status === 400) {
                        showAlert({ msgTitle: x.data })
                        setProduct(false)
                    }
                    else {
                        showAlert({})
                        setProduct(false)
                    }
                })
                .finally(() => dispatch(setPreload(false)));
        }
        else {
            showAlert({ msgTitle: "המבצע כבר התחיל, לא ניתן לשנות הגדרות", msgType: 'success' })
            return
        }
    }
    return (
        <div className="show-product-table">
            {product ? <AddProductToSale updateProduct={updateProduct} />
                : <button className='add-product' onClick={() => setProduct(true)}>הוספת מוצר למבצע</button>}
            {productList.length ?
                <table>
                    <tbody>
                        <tr>
                            <th>מק״ט</th>
                            <th>מחיקת מוצר</th>
                        </tr>
                        {productList.map(x => <tr key={x.Id}>
                            <td>
                                {x.ProductMkt}
                            </td>
                            <td>
                                <button key={x.Id} onClick={() => updateProduct(x.ProductMkt, false)}>מחק</button>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table >
                : <h2>אין רשימת מוצרים</h2>}
        </div>
    )
}
export default ProductInSale