import React, { useEffect, useState } from 'react';
import { getStationDeliveryHours, StationDeliveryHoursPost } from '../../../server/DeliveryTimeEdit'
import Input, { InputButton } from '../../containers/Input';
import showAlert from '../../../server/ShowAlert'
import { useDispatch, useSelector } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';

const DeliveryHoursCalc = () => {
    const dispatch = useDispatch();
    const [deliveriesHourMinimum, setDeliveriesHourMinimum] = useState("");
    const [deliveriesTimeBefore, setDeliveriesTimeBefore] = useState("");
    const [quantityDeliveriesHour, setQuantityDeliveriesHour] = useState("");
    const stationId = useSelector(state => state.user.stationId)
    useEffect(() => {
        GetStationDeliveryHours()
    }, [])

    const GetStationDeliveryHours = () => {
        getStationDeliveryHours(stationId)
            .then(x => {
                setDeliveriesHourMinimum(x.data.DeliveriesHourMinimum)
                setDeliveriesTimeBefore(x.data.DeliveriesTimeBefore)
                setQuantityDeliveriesHour(x.data.QuantityDeliveriesHour)

            })
            .catch(() => console.log("error"));
    }
    const saveChanges = (deliveriesHourMinimum, deliveriesTimeBefore, quantityDeliveriesHour) => {
        dispatch(setPreload(true));
        const changeHours = {
            Id: 1,
            QuantityDeliveriesHour: quantityDeliveriesHour,
            DeliveriesHourMinimum: deliveriesHourMinimum,
            DeliveriesTimeBefore: deliveriesTimeBefore,
        };
        StationDeliveryHoursPost(changeHours)
            .then(x => {
                if (x.data) {
                    showAlert({ msgTitle: 'השינויים נשמרו בהצלחה.', msgType: 'success' })
                }
            })
            .catch(() => console.log("error"))
            .finally(() => dispatch(setPreload(false)));
    }

    return (
        <div className="delivery-hours-calc">
            <div className="input">
                <Input
                    Title=" מספר הזמנות שאפשר בשעה "
                    Type="number"
                    Value={quantityDeliveriesHour}
                    InputChange={(value) => setQuantityDeliveriesHour(value)}
                />
            </div>
            <div className="input">
                <Input
                    Title="מספר שעות מינימום לפני הגעת המשלוח"
                    Type="number"
                    Value={deliveriesHourMinimum}
                    InputChange={(value) => setDeliveriesHourMinimum(value)}
                />
            </div>
            <div className="input">
                <Input
                    Title="כמה ימים להציג"
                    Type="number"
                    Value={deliveriesTimeBefore}
                    InputChange={(value) => setDeliveriesTimeBefore(value)}
                />
            </div>
            <div className="input-btn">
                <InputButton
                    Type="button"
                    Label="שמור"
                    ClassName='btn'
                    OnClick={() => saveChanges(deliveriesHourMinimum, deliveriesTimeBefore, quantityDeliveriesHour)}
                />
            </div>
        </div>
    )
}

export default DeliveryHoursCalc