import axios from 'axios';
import { url } from '../../axiosInstance';

export const getStationDeliveryHours = (stationId) => {
    return axios.get(`${url}StationDeliveryHours?StationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const StationDeliveryHoursPost = (data) => {
    return axios.post(`${url}StationDeliveryHours`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getDateType = () => {
    return axios.get(`${url}date_type`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const dateTypePost = (data) => {
    return axios.post(`${url}date_type`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getDateDayOfWeek = () => {
    return axios.get(`${url}date_day_of_week`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const dateDayOfWeekPost = (data) => {
    return axios.post(`${url}date_day_of_week`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
export const getSpecialDays = () => {
    return axios.get(`${url}date_sepcial`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const specialDaysPost = (data) => {
    return axios.post(`${url}date_sepcial`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
