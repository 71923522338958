import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action';
import showAlert from '../server/ShowAlert'
import { setPreload } from '../server/CartsOpen';

export const getFilters = () => {
    return dispatch => {
        axios.get(`${url}Parameter/GetListViews?webSite=true&program=false&haveToRefresh=false`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.token}`,
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(x => {
                dispatch({ type: actionType.SET_FILTER, payload: x.data });
            })
            .catch(() => console.log("error"));
    }
}

export const addFilter = (filter) => {
    return dispatch => {
        dispatch({ type: actionType.ADD_FILTER, payload: filter });
    }
}

export const editFilter = (filter) => {
    return dispatch => {
        dispatch({ type: actionType.EDIT_FILTER, payload: filter });
    }
}
export const ParameterDelete = (data) => {
    return dispatch => {
        dispatch(setPreload(true));
        axios.post(`${url}Parameter/Delete`,
            data,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.token}`,
                    "Access-Control-Allow-Origin": "*"
                },
            })
            .then(x => {
                if (x.data === true) {
                    dispatch({ type: actionType.DELETE_FILTER, payload: { Id: data.Id, TableName: data.TableName } })
                }
                else {
                    showAlert({ msgTitle: "לא ניתן למחוק פריט זה", msgText: 'יש מוצרים מקושרים למידע זה!', msgType: 'error' })
                }
            })
            .catch(() => showAlert({ msgTitle: "לא ניתן למחוק פריט זה", msgText: 'יש מוצרים מקושרים למידע זה!', msgType: 'error' }))
            .finally(() => dispatch(setPreload(false)));
    }
}

export const ParameterName = (parameterName) => {
    return dispatch => axios.get(`${url}Parameter/${parameterName}`,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        }).then(x =>
            dispatch({ type: actionType.SET_PARAMETER, key: parameterName, data: x.data })
        )
}
export const editParameter = (data) => {
    return dispatch => {
        axios.post(`${url}Parameter`, data,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.token}`,
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(x => {
                dispatch(editFilter(x.data))
            })
    }
}


