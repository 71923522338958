import React, { useState } from 'react';
import Slider from "react-slick";
import ProductAddToCart from "./ProductAddToCart";
import { globalFileServer } from '../../../../axiosInstance'
import ProductInfo from '../../productInfo/ProductInfo';
import { TimeDateMonthSmallYear } from '../../containers/Date';

const ProductPage = ({ selectedProd, setImageModal }) => {

  const [currSlide, setCurrSlide] = useState(1);
  const [imgView, setImgView] = useState(selectedProd.PathUrl ? selectedProd.PathUrl.split(",")[0] : false)

  const settings = {
    rtl: window.innerWidth > 1000 ? true : false,
    dots: false,
    arrows: window.innerWidth > 1000 ? true : false,
    infinite: window.innerWidth > 1000 ? true : false,
    speed: 200,
    slidesToShow: window.innerWidth > 1000 ? selectedProd.PathUrl && selectedProd.PathUrl.split(',').length < 3 ? selectedProd.PathUrl.split(',').length : 3 : 1,
    slidesToScroll: window.innerWidth > 1000 ? selectedProd.PathUrl && selectedProd.PathUrl.split(',').length < 3 ? selectedProd.PathUrl.split(',').length : 3 : 1,
    afterChange: (index) => setCurrSlide(index + 1)
  };

  return (
    <div className="product-pop-up">
      <div className="product-img">
        <img  loading="lazy" onClick={() => setImageModal(imgView)} src={selectedProd.PathUrl ? `${globalFileServer}products/${imgView}` : `${globalFileServer}/placeholder.jpg`} />
        <div
          onClick={() => setImageModal(imgView)}
          className="img"
          style={{ backgroundImage: selectedProd.PathUrl ? `url(${globalFileServer}products/${imgView})` : `url(${globalFileServer}/placeholder.jpg)` }}
        />
        <div className="images-cont flex-container">
          <div className="col-lg-12 images">
            {selectedProd.PathUrl && selectedProd.PathUrl.split(',').length > 1 ?
              <div className="images-wrapper">
                <Slider {...settings}>
                  {selectedProd.PathUrl.split(',').map((element, index) => {
                    let imgActive = !imgView && selectedProd.PathUrl.split(',')[0] === element;
                    return (
                      <div
                        key={index}
                        className={element === imgView || imgActive ? "item active" : "item"}
                        onClick={() => setImgView(element)}>
                        <img  loading="lazy" src={globalFileServer + 'products/' + element} alt={element.ProductName} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              : null}
            {selectedProd.PathUrl && selectedProd.PathUrl.split(',').length > 1 ?
              <div className="counter">
                <span>{currSlide + '/' + selectedProd.PathUrl.split(',').length}</span>
              </div>
              : null}
          </div>
        </div>
      </div>

      <div className="product-details">
        <div className='prod-info'>
          <ProductInfo selectedProd={selectedProd} />
          {selectedProd.Description ?
            <div className="description">
              <p>{selectedProd.Description}</p>
            </div>
            : null}
          {/* יחידות  */}
          <div className='prod-info-quantity'>{selectedProd.PackageQuantity ? <span >
            <span>{selectedProd.PackageQuantity}</span>
            <span> יחי' של </span>
            <span className='weight-quantity'>{selectedProd.WeightQuantity}</span>
            <span>{selectedProd.UnitMeasure}</span>
          </span> : null}</div>

        </div>

        <div className="price-and-btn">

          <h3 className="price">{(parseFloat(selectedProd.Price)).toFixed(2)} </h3>
          <ProductAddToCart element={selectedProd} haveInfo={true} />
        </div>
        <div className='extra-info'>
          {selectedProd.Barcode ? <p>ברקוד: {selectedProd.Barcode} </p> : null}
          {selectedProd.Mkt ? <p > מק״ט: {selectedProd.Mkt} </p> : null}
          {selectedProd.ShowExpired && selectedProd.Expired ? <p>תוקף: {TimeDateMonthSmallYear(selectedProd.Expired)} </p> 
          : null}
        </div>

      </div>
      <div className='kashrut'>
        {selectedProd.Kashrut && selectedProd.KashrutId != "19" ?
          <img  loading="lazy" src={globalFileServer + 'iconcss/' + selectedProd.KashrutId + '.svg'} alt="kashrut" />
          : null}
      </div>



    </div>
  )
}

export default ProductPage;