import React from 'react'

const Select = ({Title,Value,SelectChosen,Options,Name}) => {

    return (
        <div className='select'>
            <h2>{Title}</h2>
            <div className='select-ch'>
                <select className='select_option' 
                value={Value}
                onChange={(e) => SelectChosen(e.target.value, Name)} >
                    <option  value="" disabled selected>בחר</option>
                    {Options ? Options.map(x =>
                        <option className='headind' key={x.Id} value={x.Id} >
                            {x.Name}
                        </option>
                    ) : null}
                </select>
            </div>
        </div>
    )
}

export default Select
