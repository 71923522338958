import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getColumns, GetStations, AddNewCell } from '../../../server/Station';
import LocationColumns from './LocationColm';
import AddPlace from './AddPlace';
import PlaceInsert from './PlaceInsert';
import showAlert from '../../../server/ShowAlert';

const Location = () => {
    const dispatch = useDispatch();
    const [columnsId, SetColumnsId] = useState([]);

    const [columns, setColumns] = useState([]);
    const [addNewPlace, setAddNewPlace] = useState(false);


    const [selectStation, setSelectStation] = useState(null);
    const stations = useSelector(state => state.station.stations);

    useEffect(() => {
      
        if (!stations.length) {
            dispatch(GetStations())
        }
    }, []);
  
    const getCellP = (rowId, data, columnId) => {
        const copyColumns = JSON.parse(JSON.stringify(columns));
        const index = copyColumns.findIndex(x => x.Id == columnId);
        const indexRow = copyColumns[index].Rows.findIndex(x => x.Id == rowId);
        copyColumns[index].Rows[indexRow].Cells = data;
        setColumns(copyColumns);
    }

    const getRowsP = (Id, data) => {
        SetColumnsId(Id);
        const index = columns.findIndex(x => x.Id == Id);
        const copyColumns = JSON.parse(JSON.stringify(columns));
        copyColumns[index].Rows = data;
        setColumns(copyColumns);


    }

    useEffect(() => {
        if (selectStation) {
            getColumns(selectStation)
                .then(x => setColumns(x.data))
                .catch(e => console.error(e))
        }
    }, [selectStation]);
    
    const CheckAllDataInInput = (data) => {
        data.stationId = selectStation;
        AddNewCell(data)
            .then(() => {
                showAlert({msgTitle:"המיקום התווסף בהצלחה", msgType:"success"})
                setAddNewPlace(false)})
            .catch(()=>showAlert({msgTitle:"לא הצלחנו להוסיף את המיקום", msgType:"error"})
            )
    }
    return (
        <Fragment>
            <h1 className='title-role'>הגדרת מיקום</h1>
            <select className='select-place' onChange={({ target: { value } }) => setSelectStation(value)} >
                <option value="" disabled selected>בחר</option>

                {stations.map(x => <option key={x.Id} value={x.Id}>{x.Name}</option>)}
            </select>
            <div className='add-place-head'><AddPlace selectStation={selectStation} /></div>
            {addNewPlace ?
                <PlaceInsert CheckAllDataInInput={CheckAllDataInInput} /> :
                <button className='btn' onClick={() => setAddNewPlace(true)}>הוסף מיקום מפורש</button>}

            <div className='location' style={{ display: "flex" }}>
                {columns.map(x => <LocationColumns key={x.Id} columns={x} getRowsP={getRowsP} selectStation={selectStation}
                    getCellP={getCellP}  columnsId={columnsId} />)}
            </div>
           
        </Fragment >
    )
}

export default Location
