import React, { Fragment, useState } from 'react';
import { globalFileServer,globalFileIcon } from '../../../../axiosInstance'
import PopUp from "../../containers/PopUp";
import ProductPage from "./ProductPopUp"

const ProductPageWithImg = ({ close, selectedProd }) => {
    const [imageModal, setImageModal] = useState(false);
    return (
        <Fragment>
            <PopUp width='800px' close={close}>
                <ProductPage selectedProd={selectedProd} setImageModal={(data) => setImageModal(data)} />
                <div onClick={close} className="overflow"></div>
            </PopUp>
            {imageModal ?
                <div className='img-popup'>
                    <div className="close" onClick={() => setImageModal(false)}>
                        <img  loading="lazy" src={globalFileIcon + 'close-menu.svg'} alt="close"   loading="lazy" />
                    </div>
                    <img  loading="lazy" className='product-img' src={globalFileServer + "products/" + imageModal}  loading="lazy"  alt={selectedProd.ProductName} />
                </div>
                : null}


        </Fragment>
    )
}

export default ProductPageWithImg
