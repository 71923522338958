import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { globalFileServer } from '../../../../axiosInstance'
import { GetPriceList } from '../../../server/PriceList'
import { Container, Row, Col } from 'react-grid-system';
import { TimeDateMonthYear } from '../../containers/Date';
import { useSelector } from 'react-redux'

const ComparePricePopUp = ({ product, setComparePricePopUp }) => {

  const [currSlide, setCurrSlide] = useState(1);
  const [imgView, setImgView] = useState(product?.PathUrl ? product.PathUrl.split(",")[0] : false)
  const [company, setCompany] = useState([]);
  const [myCompanyData, setMyCompanyData] = useState([]);
  const { stationId, selectedProduct } = useSelector(state => ({
		stationId: state.user.stationId,
		selectedProduct: state.selectedProduct.product
	}))
	if (!product && selectedProduct) {
		product = selectedProduct
    // setImgView(selectedProduct?.PathUrl ? selectedProduct.PathUrl.split(",")[0] : false)
	}

  useEffect(() => {
    getCompareData();
    return () => {
      if(setComparePricePopUp){
      setComparePricePopUp()
      }
    }
  }, [])



  const getCompareData = () => {
    GetPriceList(product.Mkt, product.Barcode, stationId)
      .then(x => {
        setCompany(x.data.list);
        setMyCompanyData(x.data.obj[0])
      })
      .catch(() => console.log("error"));
  }

  const settings = {
    rtl: window.innerWidth > 1000 ? true : false,
    dots: false,
    arrows: window.innerWidth > 1000 ? true : false,
    infinite: window.innerWidth > 1000 ? true : false,
    speed: 200,
    slidesToShow: window.innerWidth > 1000 ? product.PathUrl && product.PathUrl.split(',').length < 3 ? product.PathUrl.split(',').length : 3 : 1,
    slidesToScroll: window.innerWidth > 1000 ? product.PathUrl && product.PathUrl.split(',').length < 3 ? product.PathUrl.split(',').length : 3 : 1,
    afterChange: (index) => setCurrSlide(index + 1)
  };



  return (
    <div className="product-page">
      <div className="product-wrapper-compare flex-container">
        <div className="col-lg-6 info-p">
          <div className="product-details">
            <div className="share"></div>
            <div className="name">
              <h2>{product.ProductName}</h2>
            </div>
            {product.Description ?
              <div className="details">
                <p>{product.Description}</p>
              </div>
              : null}
            <div className='information'>
              {product.PackageQuantity ?
                //יחידות
                <div className="prod-info-cont flex-container">
                  <div className="col-lg-7">
                    <p className="c-info">{product.PackageQuantity} </p>
                    <p className="c-info">{" x "}</p>
                    <p className="c-info">{product.WeightQuantity} </p>
                    <p className="c-info">{product.UnitMeasure}</p>
                  </div>
                </div >
                : <div className="prod-info-cont flex-container">
                  {/* <div className="col-lg-7"> */}
                    <p className="c-info">{product.WeightQuantity} </p>
                    <p className="c-info">{product.UnitMeasure}</p>
                  {/* </div> */}
                </div >
              }
              <div className='line'>|</div>
              {product.Brand ?
                // מותג
                <div className="prod-info-cont flex-container">
                  {/* <div className="col-lg-7"> */}
                    <p className="c-info">{product.Brand}</p>
                  {/* </div> */}
                </div >
                : null}
            </div>
          </div>
        </div>
        <div className="col-lg-6 image">
          <div
            className="img-comp"
            style={{ backgroundImage: product.PathUrl ? `url(${globalFileServer}products/${imgView})` : `url(${globalFileServer}/placeholder.jpg)` }}
          />
          <div className="images-cont flex-container">
            <div className="col-lg-12 images">
              {settings && product.PathUrl && product.PathUrl.split(',').length > 1 ?
                <div className="images-wrapper">
                  <Slider {...settings}>
                    {product.PathUrl.split(',').map((element, index) => {
                      let imgActive = !imgView && product.PathUrl.split(',')[0] === element;
                      return (
                        <div
                          key={index}
                          className={element === imgView || imgActive ? "item active" : "item"}
                          onClick={() => setImgView(element)}>
                          <img  loading="lazy" src={globalFileServer + 'products/' + element} alt={element.ProductName} />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                : null}
              {product.PathUrl && product.PathUrl.split(',').length > 1 ?
                <div className="counter">
                  <span>{currSlide + '/' + product.PathUrl.split(',').length}</span>
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="compare-prices">
        <div className='product-header'>
          <Container>
            <Row>
              <Col sm={3}>
                <div className="data-company">
                  <h4>מק"ט:</h4>
                  <p className="c-number">{product.Mkt}</p>
                </div>
              </Col>
              <Col sm={3}>
                <div className="data-company">
                  <h4>ברקוד:</h4>
                  <p className="c-number">{product.Barcode}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="data-company">
                  <h4>מחיר קניה:</h4>
                  <p>₪{myCompanyData["מחיר קניה"]}</p>
                </div>
              </Col>
              <Col sm={3}>
                <div className="data-company">
                  <h4>מחיר מכירה:</h4>
                  <p>₪{myCompanyData["מחיר מכירה"]}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="data-company">
                  <h4>אחוז רווח:</h4>
                  <p>{myCompanyData["אחוז רווח"]}{myCompanyData["אחוז רווח"] ? "%" : null}</p>
                </div>
              </Col>
              <Col sm={3}>
                <div className="data-company">
                  <h4>אחוז ממוצע:</h4>
                  <p>{myCompanyData["אחוז ממוצע"]}%</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="data-company">
                  <h4> מספר חנויות:</h4>
                  <p>{myCompanyData["מס חנויות"]}</p>
                </div>
              </Col>
              <Col sm={3}>
                <div className="data-company">
                  <h4> מחיר ממוצע:</h4>
                  <p>₪{myCompanyData["ממוצע"]}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="data-company">
                  <h4>מחיר מינימלי:</h4>
                  <p>₪{myCompanyData["מינימום"]}</p>

                </div>
              </Col>
              <Col sm={3}>
                <div className="data-company">
                  <h4> מחיר מקסימלי:</h4>
                  <p>₪{myCompanyData["מקסימום"]}</p>

                </div>
              </Col>
            </Row>

          </Container>
        </div>
        <div className='table-compare'>
        <table>
          <tbody>
            <tr>
              <th>שם חנות</th>
              <th>מחיר מכירה</th>
              <th>מחיר מבצע</th>
              <th>תאור מבצע</th>
              <th>עודכן</th>
            </tr>
            {company.length ? company.map(x => <tr key={x.D}>
              <td>{x["שם החברה"]}</td>
              <td>{x["מחיר מכירה"]}</td>
              <td>{x["מחיר מבצע"]}</td>
              <td>{x["תאור מבצע"]}</td>
              <td>{TimeDateMonthYear(x["תאריך שינוי"])}</td>
            </tr>
            ) : null}
          </tbody>
        </table >
        </div>
      </div>
      {/* <div className='extra-info'>
          {product.Mkt ?
            <div className="prod-extra-info-cont">
              <div>
                <p className="c-title">מק״ט</p>
              </div>
              <div className="col-lg-7">
                <p className="c-number">{product.Mkt}</p>
              </div>
            </div >
            : null}
          {product.Barcode ?
            <div className="prod-extra-info-cont">
              <div>
                <p className="c-title">ברקוד</p>
              </div>
              <div className="col-lg-7">
                <p className="c-number">{product.Barcode}</p>
              </div>
            </div >
            : null}
        </div> */}
    </div>
  )
}





export default ComparePricePopUp;