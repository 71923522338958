import axios from 'axios';
import { url } from '../../axiosInstance';

export const getInventoriesPerMktOrBarcode = (mkt, barcode, tr, stationId) => {
    mkt = mkt ? mkt : 0;
    return axios.get(`${url}InventoryLocation/GetInventorysPerMktOrBarcode?mkt=${mkt}&barcode=${barcode}&whithEmptyPlace=${tr}&stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}


export const multiMoves = (data) => {
    return axios.post(`${url}InventoryLocation/MultyMoves`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const updateProductBox = (data) => {
    return axios.post(`${url}Product/UpdateProductBox`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}


export const updateDateEndSpecial = (data) => {
    return axios.post(`${url}InventoryLocation/UpdateDateEndSepcial`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const InventoryPerMkt = (mkt, stationId) => {
    return axios.get(`${url}Inventory/PerMkt?mkt=${mkt}&stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getSaveLocations = (stationId) => {
    return axios.get(`${url}Inventory/GetSaveLocations?stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getIfProductPlaceFailedEmpty = (mkt) => {
    return axios.get(`${url}InventoryLocation/GetIfProductPlaceFailedsEmpty?mkt=${mkt}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getEmptyPlace = (mkt, stationId) => {
    return axios.get(`${url}InventoryLocation/FindEmptyPlaces?mkt=${mkt}&stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
export const getInventoryPerLocation = (Colmon, Row, Cell, stationId ) => {
    return axios.get(`${url}Inventory/GetInventoryPerLocation?Colmon=${Colmon}&Row=${Row}&Cell=${Cell}&stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}