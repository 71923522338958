import * as actionType from './action'

const items = [];


const initialState = {
    items: items,
    searchString: "",
    filterTypesShow: [],
    itemsShow: [],
    selectedFilters: [],
    typeId: null,
    filterList: [],
    filterAndSort: true,
    reloed: false
}


const toggleFilter = (payload, state) => {
    const { id, typeName } = payload;
    let flag = false;
    let selectedFilters = state.selectedFilters
    if (selectedFilters.some(x => x.id === id)) {
        selectedFilters = selectedFilters.filter(item => item.id !== id);
        if (!selectedFilters.some(item => item.typeName === typeName)) {
            flag = true;
        }
    } else {
        selectedFilters.push({ id, typeName });
    }
    let items = state.items;
    let arr = state.items;
    if (selectedFilters.length > 0) {
        state.filterTypesShow.forEach(x => {
            const arr2 = [];
            x.Items.forEach(item => {
                if (selectedFilters.filter(g => g.id === item.Id && x.ParameterName === g.typeName).length > 0) {
                    arr.forEach(element => {
                        if (element[x.ParameterName] === item.Id && !arr2.some(y => y.Mkt === element.Mkt))
                            arr2.push(element);
                    })
                }
            });
            if (arr2.length) {
                arr = [...arr2];
            }
        });
        state.filterTypesShow.forEach(item => {
            arr.forEach(element => {
                selectedFilters.filter(x => x.typeName === item.ParameterName).forEach(y => {
                    if (element[y.typeName] === y.id && !arr.some(x => x.Mkt === element.Mkt))
                        arr.push(element);
                });
            })
        });
    }
    else {
        arr = items
    }
    if (!arr.length) {
        arr = items;
    }
    const filtersTypShow = createFilters(arr, state);

    return {
        filtersTypShow,
        itemsShow: arr,
        selectedFilters,
        typeId: flag ? 0 : typeName
    }
}

const createFilters = (items, state) => {
    let filtersTypShow = [];
    state.filterList.forEach(item => {
        const arr = [];
        items.forEach(element => {
            item.Items.forEach(type => {
                if (element[item.ParameterName] === type.Id && !arr.some(j => j.Id === type.Id)) {
                    arr.push({ ...type });
                }
            });
        });

        if (arr.length > 0) {
            const newItem = { ...item }
            newItem.Items = [...arr]
            filtersTypShow.push(newItem)
        }
    });

    return filtersTypShow;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PRODUCTS: {
            const filterTypesShow = createFilters(action.payload.items, state);
            return {
                ...state,
                ...action.payload,
                itemsShow: action.payload.items,
                filterTypesShow,
                reloed:false
            }

        }
        case actionType.SET_FILTER:
            return {
                ...state,
                filterList: action.payload,
            };
        case actionType.EDIT_FILTER: {
            const filterList = [...state.filterList];
            if (action.payload.Id === -1) {
                filterList.find(z => z.Id === action.payload.elementTable.Id).Items.find(x => x.Id === -1 || x.Id).Id = action.payload.Id;
            }
            filterList.find(x => x.Id === action.payload.elementTable.Id).Items.find(x => x.Id === action.payload.itemId).Name = action.payload.value;
            return {
                ...state,
                filterList
            };
        }
        case actionType.ADD_FILTER: {
            const filterList = [...state.filterList];

            if (!filterList.find(x => x.Id === action.payload).Items.filter(x => x.Id === -1 || x.Name === "").length > 0) {
                filterList.find(x => x.Id === action.payload).Items.push({ Id: -1, Name: "" });
            }
            return {
                ...state,
                filterList
            };
        }
        case actionType.DELETE_FILTER: {
            const filterList = [];
            state.filterList.forEach(item => {
                const itemNew = { ...item };
                if (item.TableName === action.payload.TableName) {
                    itemNew.Items = item.Items.filter(x => x.Id !== action.payload.Id)
                }
                filterList.push(itemNew)
            })
            return {
                ...state,
                filterList
            };
        }
        case actionType.SORT_PRODUCTS:
            {
                const itemsShow = [...state.itemsShow];
                const items = [...state.items];
                itemsShow.find(item => action.payload.Mkt === item.Mkt).orderNumber = action.payload.orderNumber
                items.find(item => action.payload.Mkt === item.Mkt).orderNumber = action.payload.orderNumber
                items.sort((a, b) => a.CategoryFullOrderNumber - b.CategoryFullOrderNumber);
                //.sort((a, b) => a.CategoryOrderNumber - b.CategoryOrderNumber);
                itemsShow.sort((a, b) => a.CategoryFullOrderNumber - b.CategoryFullOrderNumber);
                //.sort((a, b) => a.CategoryOrderNumber - b.CategoryOrderNumber);
                //                items.sort((a, b) => a.orderNumber - b.orderNumber).sort((a, b) => a.CategoryOrderNumber - b.CategoryOrderNumber);
                //                itemsShow.sort((a, b) => a.orderNumber - b.orderNumber).sort((a, b) => a.CategoryOrderNumber - b.CategoryOrderNumber);
                return { ...state, items, itemsShow, reloed: false };
            }
        case actionType.ADD_NEW_PRODUCT:
            {
                const items = state.items
                items.sort((a, b) => { return a.orderNumber - b.orderNumber });
                items.unshift(action.payload);
                return { ...state, items };
            }

        case actionType.TOGGLE_FILTER: {
            const data = toggleFilter(action.payload, state);
            return {
                ...state,
                ...data
            };
        }
        case actionType.CLEAR_FILTER: {
            const data = createFilters(state.items, state);
            return { ...state, data, itemsShow: state.items, selectedFilters: [] };
        }
        case actionType.RELOED: {
            return { ...state, reloed: true, itemsShow: items, selectedFilters: [] };
        }
        case actionType.DELETE_SEARCH: {
            return { ...state, searchString: "" };
        }
        case actionType.SET_SEARCH_PRODUCT: {
            return { ...state, searchString: action.payload };
        }
        case actionType.SET_FILTER_SORT: {
            return { ...state, filterAndSort: action.payload };
        }
        default:
            return state
    }
}

export default reducer;