import React, { useState, useEffect } from 'react';
import Input from '../../containers/Input';


const AddProductToSale = ({ updateProduct }) => {
    const [productMkt, setProductMkt] = useState("");

    return (
        <div className="add-new-product">
            <Input
                className="input"
                type="number"
                Title="מק״ט מוצר"
                name="ProductMkt"
                Value={productMkt}
                InputChange={(e) => { setProductMkt(e) }}
            />
            <input className='btn' type="submit" value="הוסף מוצר" onClick={() => updateProduct(productMkt, true)} />
        </div>
    )
}

export default AddProductToSale