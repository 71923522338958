//cart
export const TOGGLE_CART = "TOGGLE_CART";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const EDIT_HOME_OBJ = "EDIT_HOME_OBJ";
export const SET_UN_PRELOAD = "SET_UN_PRELOAD";
export const SET_PRELOAD = "SET_PRELOAD";

//user
export const SET_STATIONS = "SET_STATIONS";


//user
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT"
export const SET_IS_VALID_TOKEN = "SET_IS_VALID_TOKEN";
export const USER_ENTRY = "USER_ENTRY";
export const VERIFIED_EMAIL = "VERIFIED_EMAIL";

//category
export const SET_ALL_CATEGORY = "SET_ALL_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const SET_IMG = "SET_IMG";



export const SET_FILTER = "SET_FILTER";
export const ADD_FILTER = "ADD_FILTER";
export const EDIT_FILTER = "EDIT_FILTER";
export const DELETE_FILTER = "DELETE_FILTER";




// productsInCart

export const ADD_PRODUCT_IN_CART = "ADD_PRODUCT_IN_CART";
export const SET_ALL_PRODUCT = "SET_ALL_PRODUCT";
export const SET_ORDER_TIME = "SET_ORDER_TIME";
export const CANCEL_ERROR = "CANCEL_ERROR";
export const CLEAR_CARD = "CLEAR_CARD";
export const SET_DELIVERY_PRICE = "SET_DELIVERY_PRICE";


//displayProduct
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SORT_PRODUCTS = "SORT_PRODUCTS";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const RELOED = "RELOED";

export const SET_SEARCH_PRODUCT = "SET_SEARCH_PRODUCT";
export const DELETE_SEARCH = "DELETE_SEARCH"

export const SET_FILTER_SORT = "SET_FILTER_SORT";

//shoppingList
export const SET_SHOP_LIST = "SET_SHOP_LIST";
export const ADD_NEW_SHOP_LIST = "ADD_NEW_SHOP_LIST";


// selectedProduct
export const SET_SELCTED_PRODUCT = "SET_SELCTED_PRODUCT";
export const SET_UNSELCTED_PRODUCT = "SET_UNSELCTED_PRODUCT";
export const SET_UPDAATE_SELCED_PRODUCT = "SET_UPDAATE_SELCED_PRODUCT";
export const SET_ALL_SELECTED_PRODUCT = "SET_ALL_SELECTED_PRODUCT";


//parameters
export const SET_PARAMETER="SET_PARAMETER";