import React, {  Fragment } from 'react';
import { globalFileIcon } from '../../../axiosInstance';

const Address = ({ address, removeAddress, setActiveAddress, setNewAddress }) => {
	return (
		<div className="address-class">
			<div className="address-data">
				{address ?
					<Fragment>
						{address.map(item => {
							return (
								<button
									style={{
										backgroundImage: `url(${item.MainAddress ? globalFileIcon  + 'addressBg.svg' : globalFileIcon + 'addressGrayBg.svg'})`,
										backgroundRepeat: 'no-repeat',
										backgroundSize: '100%',
										backgroundColor: 'transparent',

									}}
									className={item.MainAddress ? "address-data-list active" : "address-data-list"}
									key={item.Id}
									onClick={() => setActiveAddress(item)}>
									<img  loading="lazy" className="delete" onClick={() => removeAddress(item.Id)} src={globalFileIcon + 'deleteLocationAndListI.svg'} alt="delete" />
									<img  loading="lazy" className='location-icon' src={item.MainAddress ? globalFileIcon + 'locationDefult.svg' : globalFileIcon + 'locationGray.svg'} />
									<span className='location-name'>{item.Name}</span>
									<span className='location-address-name'>
										{item.StreetName + ' ' + item.StreetNumber + ', ' + item.Town}
									</span>
								</button>
							)
						})}
						<button
							style={{
								backgroundImage: `url(${globalFileIcon + 'addressGrayBg.svg'})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: '100%',
								backgroundColor: 'transparent',
							}}

							onClick={setNewAddress}
							className="add-new-address">
							<img  loading="lazy" className='location-add-icon' src={globalFileIcon + 'addNewAddressAndList.svg'} />
							<span className='location-add-name'>הוספת כתובת</span>
						</button>
					</Fragment>
					: null}
			</div>
		</div >

	)
}
export default Address;

