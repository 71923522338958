import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form';
import { UpdateProduct } from '../../../server/Product'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Input, { Select } from "../../containers/InputForm";

const schema = yup.object({
}).required();



const Logistica = () => {


    const { product,
        parameters: { p_manufacturer, p_provider, p_storage_mode,
            p_unload_manufacturer_package, p_box_type, p_box_storge_way }
    } = useSelector(state => ({
        product: state.selectedProduct.product,
        parameters: state.parameters
    }));


    const { register, formState: { errors }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: { ...product },
        resolver: yupResolver(schema),
    });





    const updateProduct = (value, paramName) => {
        const val = {
            Mkt: product.Mkt,
            Title: paramName,
            value: value
        };
        UpdateProduct(val)
    }




    const tryUpdate = ({ target: { id, value } }) => {
        handleSubmit()
        if (!errors[id]) {
            updateProduct(value, id);
        }
    }



    return (
        <form className="product-details-edit" >


            <Container>

                <Row>

                    <SelectCol sm={4} name="ManufacturerId" errors={errors} register={register} onChange={tryUpdate}
                        options={p_manufacturer} label="יצרן" />
                    <SelectCol sm={4} name="ProviderId" errors={errors} register={register} onChange={tryUpdate}
                        options={p_provider} label="ספק" />
                    <SelectCol sm={4} name="ExchangeProviderId" errors={errors} register={register} onChange={tryUpdate}
                        options={p_provider} label="ספק חילופי" />
                    <SelectCol sm={4} name="ExchangeProviderId2" errors={errors} register={register} onChange={tryUpdate}
                        options={p_provider} label="ספק חילופי2" />
                    <InputCol errors={errors} label={"משקל ברוטו"} name={"GrossWeight"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"סטיית משקל ברוטו"} name={"GrossWeightLoss"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"כמות במארז יצרן"} name={"ManufacturerPackageQuantity"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"כמות במיני מארז"} name={"MiniPackageQuantity"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"כמות במאסטר מארז"} name={"MasterPackageQuantity"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"כמות במשטח"} name={"FlatQuantity"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"משקל ברוטו מארז יצרן"} name={"ManufacturerPackageWeight"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol errors={errors} label={"מידות אריזות יצרן"} name={"ManufacturerPackageSize"} onBlur={tryUpdate}
                        type='number' sm={4} register={register} />
                    <InputCol sm={4} errors={errors} name={"ManufacturerPackageQuantity"} onBlur={tryUpdate}
                        register={register} type="text" label="יחידות במארז יצרן" />
                    <SelectCol errors={errors} label={"צורת פריקת מארז יצרן"} onChange={tryUpdate}
                        register={register} sm={4} name={"UnloadManufacturerPackageId"} options={p_unload_manufacturer_package} />
                    <InputCol sm={4} name="BoxQuantity" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="כמות בארגז" />
                    <SelectCol sm={4} name="BoxTypeId" errors={errors} onChange={tryUpdate}
                        register={register} options={p_box_type} label="סוג מיקום" />
                    <SelectCol sm={4} name="BoxStorageWayId" errors={errors} onChange={tryUpdate}
                        register={register} options={p_box_storge_way} label="צור ת אחסון בארגז" />
                    <InputCol sm={4} name="Height" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="גובה פריט" />
                    <InputCol sm={4} name="Cube" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="רמת נפח" />
                    <InputCol sm={4} name="Action" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="רמת פעילות" />
                    <SelectCol sm={4} name="StorageModeId" errors={errors} register={register} onChange={tryUpdate}
                        options={p_storage_mode} label="טמפרטורת אחסון" />
                    <InputCol sm={4} name="ExpiredDays" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="חיי מדף בימים" />
                    <InputCol sm={4} name="MinExpiredDays" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="מינימום ימי פג תוקף" />

                    <InputCol sm={4} name="MinExpiredDays" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="מינימום ימי פג תוקף" />
                    <div className='check-box'>

                        <div className='checkbox-style'>
                            <Input name="Separated" errors={errors} onBlur={tryUpdate}
                                register={register} type="checkBox" label=" מוצר מופרד" /></div>
                        <div className='checkbox-style'>
                            <Input name="ExpiredAllert" errors={errors} onBlur={tryUpdate}
                                register={register} type="checkBox" label=" התראת פג תוקף" /></div>
                    </div>
                </Row>
            </Container>

        </form >
    )
}

const InputCol = ({ name, errors, label, type, register, sm, onBlur }) => {
    return (
        <Col sm={sm}>
            <Input name={name} errors={errors} onBlur={onBlur}
                register={register} label={label} type={type} />
        </Col>)
}
const SelectCol = ({ name, errors, label, register, sm, options, onChange }) => {
    return (
        <Col sm={sm}>
            <Select
                name={name} errors={errors} {...register(name)}
                options={options} label={label} onChange={onChange} />
        </Col>)
}

export default Logistica;