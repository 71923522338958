import React, { Fragment, useEffect, useState } from 'react';
import { EditorState, Editor, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TimeYearMonthDate from '../containers/Date'

const App = ({ convertContentToHTML, homeObj, save }) => {

	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

	useEffect(() => {
		if (homeObj.Text) {
			const blocksFromHTML = htmlToDraft(homeObj.Text);
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap,
			);
			setEditorState(e => EditorState.push(e, state))
		}
	}, [])

	const handleEditorChange = (state) => {
		setEditorState(state);
		saveEditor();
	}

	const saveEditor = () => {
		const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		convertContentToHTML(currentContentAsHTML, "Text", homeObj.Id);
	}


	return (
		<Fragment>
			<Editor
				editorState={editorState}
				onChange={handleEditorChange}
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
			/>
			<input
				type="date"
				value={TimeYearMonthDate(homeObj.FromDate)}
				onChange={(e) => convertContentToHTML(e.target.value, "FromDate", homeObj.Id)}
			/>
			<input
				type="date"
				value={TimeYearMonthDate(homeObj.ToDate)}
				onChange={(e) => convertContentToHTML(e.target.value, "ToDate", homeObj.Id)}
			/>
			<button onClick={() => save(homeObj.Id)} >שמירה</button>
		</Fragment>
	)
}
export default App;