import React from 'react'

const DetailsWindow = ({ title, children }) => (
    <div className='details-window'>
        <span className='header-window'>
            <h2>{title}</h2>
            {children.length ? children[0] : null}
        </span>
        {children.length ? children[1] : children}
    </div>


);

export default DetailsWindow;
