import React, { useState, useEffect } from "react";
import { globalFileServer,globalFileIcon } from '../../axiosInstance';
import $ from 'jquery';

const pluses = /\+/g;
const config = {};

const Footer = () => {

	const [accessibilityOpen, setAccessibilityOpen] = useState(false)

	useEffect(() => {
		onLoadPage();
	}, [])

	const setCookie = (key, value) => {
		let result = key ? undefined : {};
		let cookies = document.cookie ? document.cookie.split('; ') : [];
		for (let i = 0; i < cookies.length; i++) {
			let parts = cookies[i].split('=');
			let name = decode(parts.shift());
			let cookie = parts.join('=');
			if (key === name) {
				result = read(cookie, value);
				break;
			}
			if (!key && (cookie = read(cookie)) !== undefined) {
				result[name] = cookie;
			}
		}
		return result;
	}

	const removeCookie = (key, options) => {
		setCookie(key, '', $.extend({}, options, { expires: -1 }));
		return !setCookie(key);
	}

	const decode = (s) => {
		return config.raw ? s : decodeURIComponent(s);
	}

	const parseCookieValue = (s) => {
		if (!s.indexOf('"')) {
			s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
		}
		try {
			s = decodeURIComponent(s.replace(pluses, ' '));
			return config.json ? JSON.parse(s) : s;
		} catch (e) { }
	}

	const read = (s, converter) => {
		const value = config.raw ? s : parseCookieValue(s);
		return $.isFunction(converter) ? converter(value) : value;
	}

	const openAccessibility = () => {
		if (accessibilityOpen) {
			$('.accessibility').slideUp();
		}
		else {
			$('.accessibility').slideDown();
		}
		setAccessibilityOpen(!accessibilityOpen);
	}

	const onLoadPage = () => {
		if (!setCookie("bodyClass")) {
			const a = setCookie("bodyClass");
			$("body").addClass(a)
		}
		if (!setCookie("bodyZoom")) {
			const b = setCookie("bodyZoom");
			$("body").addClass(b)
		}
		if (!setCookie("bodyGrayscale")) {
			const c = setCookie("bodyGrayscale");
			$("body").addClass(c)
		}
		if (!setCookie("bodyInvert")) {
			const d = setCookie("bodyInvert");
			$("body").addClass(d)
		}
		if (!setCookie("bodyLinks")) {
			const e = setCookie("bodyLinks");
			$("body").find("a").addClass(e)
		}
		if (!setCookie("bodyLinksBold")) {
			const f = setCookie("bodyLinksBold");
			$("body").find("a").addClass(f)
		}

	}

	const decrease_links = () => {
		$("body").find("a").addClass("links_bolder");
		setCookie("bodyLinksBold", "links_bolder");
	}

	const increase_links = () => {
		$("body").find("a").addClass("underline_links");
		setCookie("bodyLinks", "underline_links");
	};

	const increase_fs = () => {
		if ($("body").hasClass("sizeL")) {
			$("body").removeClass("sizeL").addClass("sizeXL");
			setCookie("bodyClass", "sizeXL");
		}
		else if ($("body").hasClass("sizeXL")) {
			$("body").removeClass("sizeXL").addClass("sizeXXL");
			setCookie("bodyClass", "sizeXXL");
		}
		else if ($("body").hasClass("sizeXXL")) {
			$("body").removeClass("sizeXXL").addClass("sizeXXXL");
			setCookie("bodyClass", "sizeXXXL")
		}
		else {
			$("body").addClass("sizeL");
			setCookie("bodyClass", "sizeL");
		}
	}


	const invert_body = () => {
		if ($("body").hasClass("grayscale_body")) {
			$("body").removeClass("grayscale_body");
			removeCookie("bodyGrayscale");
		}
		$("body").addClass("invert_body");
		setCookie("bodyInvert", "invert_body");
	}
	const decrease_fs = () => {
		if ($("body").hasClass("sizeXXXL")) {
			$("body").removeClass("sizeXXXL").addClass("sizeXXL");
			removeCookie("bodyClass");
			setCookie("bodyClass", "sizeXXL");
		}
		else if ($("body").hasClass("sizeXXL")) {
			$("body").removeClass("sizeXXL").addClass("sizeXL");
			removeCookie("bodyClass");
			setCookie("bodyClass", "sizeXL")
		}
		else if ($("body").hasClass("sizeXL")) {
			$("body").removeClass("sizeXL").addClass("sizeL");
			removeCookie("bodyClass");
			setCookie("bodyClass", "sizeL");
		}
		else if ($("body").hasClass("sizeL")) {
			$("body").removeClass("sizeL");
			removeCookie("bodyClass");
		}
	}
	const restore_fs = () => {
		if ($("body").hasClass("sizeXXXL")) { $("body").removeClass("sizeXXXL") }
		if ($("body").hasClass("sizeXXL")) { $("body").removeClass("sizeXXL") }
		if ($("body").hasClass("sizeXL")) { $("body").removeClass("sizeXL") }
		if ($("body").hasClass("sizeL")) { $("body").removeClass("sizeL") }
		removeCookie("bodyClass");
	}
	const zoom_in_body = () => {
		if ($("body").hasClass("zoomL")) {
			$("body").removeClass("zoomL").addClass("zoomXL");
			setCookie("bodyZoom", "zoomXL");
		}
		else if ($("body").hasClass("zoomXL")) {
			$("body").removeClass("zoomXL").addClass("zoomXXL");
			setCookie("bodyZoom", "zoomXXL");
		}
		else {
			$("body").addClass("zoomL");
			setCookie("bodyZoom", "zoomL")
		}

	}
	const zoom_out_body = () => {
		if ($("body").hasClass("zoomXXL")) {
			$("body").removeClass("zoomXXL").addClass("zoomXL");
			removeCookie("bodyZoom");
			setCookie("bodyZoom", "zoomXL");

		} else if ($("body").hasClass("zoomXL")) {
			$("body").removeClass("zoomXL").addClass("zoomL");
			removeCookie("bodyZoom");
			setCookie("bodyZoom", "zoomL");
		}
		else if ($("body").hasClass("zoomL")) {
			$("body").removeClass("zoomL");
			removeCookie("bodyZoom");

		}
	}

	const restore_zoom_body = () => {
		if ($("body").hasClass("zoomXXL")) { $("body").removeClass("zoomXXL") }
		if ($("body").hasClass("zoomXL")) { $("body").removeClass("zoomXL") }
		if ($("body").hasClass("zoomL")) { $("body").removeClass("zoomL") }
		removeCookie("bodyZoom");
	}

	const grayscale_body = () => {
		if ($("body").hasClass("invert_body")) {
			$("body").removeClass("invert_body");
			removeCookie("bodyInvert");
		}

		$("body").addClass("grayscale_body");
		setCookie("bodyGrayscale", "grayscale_body")
	}

	const restore_color_body = () => {
		if ($("body").hasClass("grayscale_body")) {
			$("body").removeClass("grayscale_body");
			removeCookie("bodyGrayscale");
		}
		if ($("body").hasClass("invert_body")) {
			$("body").removeClass("invert_body");
			removeCookie("bodyInvert");
		}
	}
	const restore_links = () => {
		$("body").find("a").removeClass("underline_links");
		removeCookie("bodyLinks");
		$("body").find("a").removeClass("links_bolder");
		removeCookie("bodyLinksBold");
	}

	return (
		<footer >
			<div>
				<div className="accessibility-wrapper">
					<div className="accessibility">
						<div className="accessibility-contant flex-container">
							<div className="col-lg-3">
								<h3>גודל הגופן</h3>
								<ul>
									<li onClick={increase_fs} ><span><img  loading="lazy" src={globalFileServer + "accessibility/increase-font.png"} alt="increase-font" /></span> <a>הגדל גופן</a></li>
									<li onClick={decrease_fs} ><span><img  loading="lazy" src={globalFileServer + "accessibility/decrease-font.png"} alt="decrease-font" /></span> <a>הקטן גופן</a></li>
									<li onClick={restore_fs} ><span><img  loading="lazy" src={globalFileServer + "accessibility/refresh.png"} alt="generic-text" /></span> <a>שחזר את גודל הגופן</a></li>
								</ul>
							</div>
							<div className="col-lg-3 col-zoom">
								<h3>תצוגה</h3>
								<ul>
									<li onClick={zoom_in_body}><span><img  loading="lazy" src={globalFileServer + "accessibility/zoom-in.png"} alt="zoom-in" /></span> <a>הגדל את התצוגה</a></li>
									<li onClick={zoom_out_body}><span><img  loading="lazy" src={globalFileServer + "accessibility/zoom-out.png"} alt="zoom-out" /></span> <a>הקטן את התצוגה</a></li>
									<li onClick={restore_zoom_body}><span><img  loading="lazy" src={globalFileServer + "accessibility/refresh.png"} alt="refresh" /></span> <a>שחזר תצוגה</a></li>
								</ul>
							</div>
							<div className="col-lg-3">
								<h3>צבעוניות</h3>
								<ul>
									<li onClick={grayscale_body}><span><img  loading="lazy" src={globalFileServer + "accessibility/black-white.png"} alt="black-white" /></span> <a>שחור לבן</a></li>
									<li onClick={invert_body}><span><img  loading="lazy" src={globalFileServer + "accessibility/contrast.png"} alt="contrast" /></span> <a>הפוך צבעים</a></li>
									<li onClick={restore_color_body}><span><img  loading="lazy" src={globalFileServer + "accessibility/refresh.png"} alt="refresh" /></span> <a>שחזר צבעים</a></li>
								</ul>
							</div>
							<div className="col-lg-3">
								<h3>לינקים</h3>
								<ul>
									<li onClick={increase_links}><span><img  loading="lazy" src={globalFileServer + "accessibility/underline.png"} alt="underline" /></span> <a>קו מתחת ללינקים</a></li>
									<li onClick={decrease_links}><span><img  loading="lazy" src={globalFileServer + "accessibility/bold.png"} alt="bold" /></span> <a>הדגש לינקים</a></li>
									<li onClick={restore_links}><span><img  loading="lazy" src={globalFileServer + "accessibility/refresh.png"} alt="refresh" /></span> <a>שחזר לינקים</a></li>
								</ul>
							</div>
							<div className="close-accessibility">
								<img  loading="lazy" onClick={openAccessibility} src={globalFileIcon+'close.svg'} alt="close" />
							</div>
						</div>
						<div className="accessibility-footer"></div>

					</div>
				</div>

				<div className='open-accessibility'>
					<li className="data" onClick={openAccessibility}>
						<img  loading="lazy"  src={globalFileIcon + 'accessibility.svg'} alt="accessibility" />
						<span>נגישות</span>
					</li>
					{/* <img  loading="lazy" onClick={openAccessibility} src={globalFileServer + "wheelchair-access.png"} alt="accessibility" /> */}
				</div>
			</div>
		</footer>
	)
}

export default Footer