import React, { useState } from 'react'
import Input from "../../containers/Input";
import Select from "../../containers/Select";
import { saveCells } from '../../../server/Station';
import showAlert from '../../../server/ShowAlert';
import { useSelector } from 'react-redux';

const LocationCell = ({ cell }) => {

    const [Active, setActive] = useState(cell?.Active);
    const [LocationCellTypeId, setLocationCellTypeId] = useState(cell?.LocationCellTypeId)
    const { location_cell_type, status_location } = useSelector(state => state.parameters)
    const submit = (event) => {
        event.preventDefault()
        const data = {
            ...cell,
            Active,
            LocationCellTypeId
        }
        saveCells(data)
            .then(x => {
                x ? showAlert({ msgTitle: 'נתוני הארגז נשמרו בהצלחה', msgText: '', msgType: 'success' }) :
                    showAlert({ msgTitle: "היה בעיה בשמירת הנתונים", msgText: '' })
            })
            .catch(x => { })

    }
    return (
        <div className='box-details' key={cell.Id} >
            <Input Title="שם ארגז" Value={cell.Name} Disabled={true} />
            <Select Value={Active} SelectChosen={(value) => setActive(value)}
                Options={status_location} Title="פעיל" />
            <Select Value={LocationCellTypeId} SelectChosen={(value) => setLocationCellTypeId(value)}
                Options={location_cell_type} Title="מיקום מיוחד" />
            <input type='button' onClick={submit} value="שמור שינוי" />
        </div>)

}

export default LocationCell
