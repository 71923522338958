import React, { useState, useEffect } from 'react';
import { DeliveryHoursGet, DeliveryHoursSetTime } from '../../server/DeliveryHours'
import { useDispatch, useSelector  } from 'react-redux';
import { TimeDateMonth, HebrewDate } from '../containers/Date'
import DetailsWindow from '../containers/DetailsWindow';
import { orderBy } from 'lodash';


const DeliveryTime = () => {
    const dispatch = useDispatch();
    const { orderTime, stationId } = useSelector(state => {
        return {
            orderTime: state.productsInCart.orderTime,
            stationId: state.user.stationId
        }
    } );
    const today = new Date()
    const tomorrow= new Date()
    tomorrow.setDate(today.getDate() + 1)
    const [days, setDays] = useState([]);

    useEffect(() => {
        GetTimes();
        setTimeout(() => { window.scrollTo(0, 0) }, 200);
    }, [])

    const GetTimes = () => {
        DeliveryHoursGet(1)
            .then(x => {
                const arr = []
                const groupByDate = x.data.reduce((group, product) => {
                    const { Date } = product
                    group[Date] = group[Date] ? group[Date] : []
                    group[Date].push(product)
                    return group;
                }, {});
                for (let x in groupByDate) {
                    arr.push({ key: { date: x, day: groupByDate[x][0].DateName }, value: groupByDate[x] })
                }
                setDays(arr)
            })
            .catch(() => console.log("error"));
    }

    const HandleCheck = (date) => {
        const data = {
            stationId,
            OrderTime: date.FullDate,
        };
        dispatch(DeliveryHoursSetTime(data));
    }

    return (
        <DetailsWindow title='זמני משלוח'>
        <div className="delivery-time">
            {orderBy(days, [x => new Date(x.key.date)], ['desc']).map((x, index) => 
                <div className='day-data' key={index}>
                    <div className="day-title">
                        {TimeDateMonth(today)===TimeDateMonth(x.key.date)? "היום: "
                        : TimeDateMonth(tomorrow)===TimeDateMonth(x.key.date)? `מחר, ${x.key.day}, ${HebrewDate(new Date(x.key.date) )} - ${TimeDateMonth(x.key.date)}`
                        : `${x.key.day}, ${HebrewDate(new Date(x.key.date) )} - ${TimeDateMonth(x.key.date)}`}
                    </div>

                    <div className='list-hours'>
                        {x.value.map((date, index) =>
                            <ul key={index} onClick={() => HandleCheck(date)}>
                                <div className={orderTime === date.FullDate ? "checked-time" : !date.CanNow ? "can-not-check" : "ul-hour"}>
                                    {date.Hour}:00-{date.Hour + 2}:00
                                </div>
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
        </DetailsWindow>
    )
}

export default DeliveryTime