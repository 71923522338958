import React, { useEffect, Fragment, useState } from 'react';
import moment from 'moment'
import PayPopup from '../shopCart/PayPopup';
import { globalFileServer, globalFileIcon } from '../../../../axiosInstance'
import { GetProductsToOrder } from '../../../server/ProductToOrder'
import { CollectSiteProductSetOrder } from '../../../server/CollectSite'
import { orderPerCustomer } from '../../../server/Order'
import { useSelector, useDispatch } from 'react-redux';
import ProductInfo from '../../productInfo/ProductInfo';

const UserHistory = ({ name }) => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user.user);
	const [items, setItems] = useState([]);
	const toShow = 7;
	const [currentPage, setCurrentPage] = useState(1);
	const [orderIdPay, setOrderPay] = useState(null);
	const [HaveToPay, setHaveToPay] = useState(null);


	useEffect(() => {
		getItems();
		setTimeout(() => { window.scrollTo(0, 0) }, 200);
	}, [])


	const PayNow = (orderId, sum) => {
		setOrderPay(orderId);
		setHaveToPay(sum);
	}

	const closePayPopup = () => {
		setOrderPay(null)
	}

	const CloseProduct = (orderId) => {
		const orders = [...items]
		const selectOrderIndex = orders.findIndex(x => x.OrderId === orderId);
		const selectOrder = orders[selectOrderIndex]
		selectOrder.ProductsShow = false;
		setItems(orders)
	}
	const getProductToOrder = (orderId) => {

		const orders = [...items]
		const selectOrderIndex = orders.findIndex(x => x.OrderId === orderId);
		const selectOrder = orders[selectOrderIndex]
		selectOrder.ProductsShow = true;
		if (!selectOrder.Products) {
			GetProductsToOrder(orderId)
				.then(x => {
					selectOrder.Products = x.data;
					orders[selectOrderIndex] = selectOrder;
					setItems(orders)
				})
				.catch(() => console.log("error"));
		}
		else {
			setItems(orders)
		}
	}

	const setPage = (currentPage) => {
		setCurrentPage(currentPage);
		window.scrollTo(0, 0);
	}
	const getItems = () => {
		orderPerCustomer(user.Id)
			.then(x => setItems(x.data))
			.catch(() => console.log("error"));
	}
	
	let pagination = [...Array(Math.ceil(items.length / toShow)).keys()];
	return (
		<div className="user-history">
			{orderIdPay ? <PayPopup
				closePayPopup={closePayPopup}
				orderId={orderIdPay}
				SumPay={HaveToPay}
				tranmode={false}
			/> : null}
			<p className='user-history-header'> <img  loading="lazy" src={globalFileIcon + 'history_order.svg'} alt="history_order" />
				ההזמנות של {name}</p>

			<div className="list-history">
				{!items.length ? <h1 className="no-products">אין הזמנות</h1> : null}
				{items.map((element, index) => index < toShow * currentPage && index >= (currentPage - 1) * toShow ?
					<Fragment key={index}>
						<div className="item">
							<div className='header-history'>מס' הזמנה:<br />{element.OrderWebId}</div>
							<div className='details-history'>
								<p className="price">סך הזמנה:{element.PaymentOrg}  ₪</p>
								<p className="status">סטטוס: {element.StatusName}</p>

								<p>לידי: {element.CustomerName}</p>
								<p>בכתובת: {element.FullAddress}</p>
								<p>בתאריך: {moment(element.ExpectedDate).format("DD.MM.yy HH:mm")}</p>
								{element.Comment ? <p>הערות: {element.Comment}</p> : null}
								<br />

								<p>חיוב בכרטיס אשראי:</p>
								<p>{element.Digit4}************</p>
								<p>בתאריך: {element.PaymentDateTime ? moment(element.PaymentDateTime).format("DD.MM.yy") : ""}</p>

								{element.HaveToPay && element.CanPay ?
									<div className="wrap">
										<button className='btn' onClick={() => PayNow(element.OrderId, element.HaveToPay)}>לתשלום &gt;</button>
										<p>{element.HaveToPay}</p>
									</div>
									: null}
								<button className='btn' onClick={() => dispatch(CollectSiteProductSetOrder(element.OrderId))}>העתק לסל &gt;</button>
								{!element.ProductsShow ?
									<button className='btn' onClick={() => getProductToOrder(element.OrderId)}>הצגת הזמנה</button>
									:
									<button className='btn-click' onClick={() => CloseProduct(element.OrderId)}>הסתר הזמנה</button>
								}
							</div>
						</div>

						{element.ProductsShow && element ?
							<div className='show-items-list'>
								<table>
									<tbody>
										<tr className='header'>
											<th>מוצר</th>
											<th>כמות</th>
											<th>מחיר יחי'</th>
											<th>סה"כ</th>
										</tr>
										{element.Products.map(el => {
											return (
												<tr key={el.Id}>
													<td className='product' >
														<img  loading="lazy" src={el.PathUrl ? globalFileServer + 'products/' + el.PathUrl.split(",")[0] : globalFileServer + 'placeholder.jpg'} alt={el.ProductName} />
														<span className='product-data'>
															<p className='description'>{el.ProductName}</p>
															<ProductInfo selectedProd={el} />
														</span>
													</td>
													<td className='quantity'>
														{el.Quantity}
													</td>
													<td className='price'>
														₪{el.Price}
													</td>
													<td>
														₪{el.Quantity * el.Price}
													</td>
												</tr>
											)
										}
										)}
										<tr className='total-sum'>
											<td>סה"כ</td>
											<td><p className='num-prod'>{element.NumOfProducts}</p></td>
											<td></td>
											<td>₪{element.PaymentOrg}</td>
										</tr>
									</tbody>
								</table >
							</div>
							: null}
					</Fragment>
					: null)
				}
			</div>
			{pagination.length > 1 ?
				<div className="pagination">
					<ul>
						{currentPage > 1 ?
							<li onClick={() => setPage(currentPage - 1)}>
								<img  loading="lazy" src={globalFileIcon + 'right-arrow.svg'} alt="back" />
							</li>
							: null}
						{pagination.map((element, index) => {
							let pageN = element + 1;
							return (
								<li onClick={() => setPage(pageN)} key={index} className={pageN === currentPage ? 'active' : null}>
									<span>{pageN}</span>
								</li>
							);
						})}
						{currentPage < pagination.length ?
							<li onClick={() => setPage(currentPage + 1)}>
								<img  loading="lazy" src={globalFileIcon + 'left-arrow.svg'} alt="next" />
							</li>
							: null}
					</ul>
				</div>
				: null}
		</div>
	)
}

export default UserHistory;