import React from "react";
import { globalFileServer, globalFileIcon } from '../../../axiosInstance'
import { NavLink } from "react-router-dom";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="legal-details">
				<a target="_blank" className="privacy" href={globalFileServer + 'med.pdf'}>תקנון האתר |</a>
				<a target="_blank" className="privacy" href={globalFileServer + 'privacy.pdf'}>  מדיניות הפרטיות </a>
				<a target="_blank" className="privacy" href={globalFileServer + 'accessibility.pdf'}>|  הצהרת נגישות </a>
				<NavLink className="privacy" to="/workers-needed" target="_self" onClick={() => window.scrollTo(0, 0)}>| דרושים </NavLink>

			</div>
			<div className="copyright">
				<img  loading="lazy" src={globalFileIcon + 'footer-site.svg'} alt="footer-site" />
				<span>כל הזכויות שמורות להמזון בע"מ</span>
			</div>
		</footer>
	)

}
export default Footer