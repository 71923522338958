import React, { useEffect, useState } from 'react';
import { customerListId, addToCard } from '../../../server/ShoppingList'
import { useParams } from 'react-router-dom'
import showAlert from '../../../server/ShowAlert';
import { CollectSiteProductGet } from '../../../server/CollectSite';
import { addProductToList } from "../../../server/ShoppingList"
import { setPreload } from '../../../server/CartsOpen';
import { useDispatch, useSelector } from "react-redux";

const UserShoppingListProduct = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const stationId = useSelector(state => state.user.stationId)
    const [products, setProducts] = useState([]);
    const [listName, setListName] = useState("");
    const [listId, setListId] = useState("");

    useEffect(() => {
        getProductList();
    }, [])
    const getProductList = () => {
        customerListId(id)
            .then(x => {
                if (x.data) {
                    setListId(x.data.Id)
                    setListName(x.data.Name)
                    if (x.data.customer_list_product) {
                        setProducts(x.data.customer_list_product)
                    }
                }
            }
            ).catch(() => showAlert());

    }
    const AddToCard = () => {
        addToCard(id, stationId)
            .then(x => {
                if (x.data) {
                    dispatch(CollectSiteProductGet())
                    showAlert({ msgTitle: "הרשימה התווספה לעגלת קניות", msgType: "success" })
                }
                else {
                    showAlert({ msgTitle: "הייתה בעיה בהוספת הרשימה לעגלת קניות", msgType: "error" })
                }
            }
            ).catch(() => showAlert());
    }
    const SetQuantity = (value, id) => {
        const product = [...products]
        product.find(item => item.Id === id).Quantity = value;
        setProducts(product)
    }
    const AddToShopList = (id, quantity, mkt) => {
        dispatch(setPreload(true));
        const val = {
            Id: id,
            Quantity: quantity,
            ProductMkt: mkt,
            CustomerListId: listId,
        }
        addProductToList(val)
            .then(x => {
                if (x.data) {
                    showAlert({ msgTitle: "הכמות השתנה", msgType: "success" })
                }
                else {
                    const product = [...products]
                    product.splice(product.findIndex(item => item.Id === id), 1)
                    setProducts(product)
                    showAlert({ msgTitle: "המוצר נמחק מהרשימה ", msgType: "error" })
                }
            })
            .catch(() => showAlert())
            .finally(() => dispatch(setPreload(false)));
    }
    return (
        <div className="shopping-list-product">
            {listName ? <div>
                <h1>שם הרשימה: {listName}</h1>
                {products.length > 0 ? <div><table><tbody>
                    <tr>
                        <th>שם המוצר</th>
                        <th>כמות</th>
                        <th></th>
                        <th></th>
                    </tr>
                    {products.map(prod => (
                        <tr key={prod.Id}>
                            <td>{prod.ProductName}</td>
                            <td> <input
                                type="number"
                                value={prod.Quantity}
                                onChange={(e) => SetQuantity(e.target.value, prod.Id)}
                                onBlur={(e) => SetQuantity(e.target.value, prod.Id)}
                            /></td>
                            <td><button className='btn' onClick={() => AddToShopList(prod.Id, prod.Quantity, prod.ProductMkt)}>שמור</button></td>
                            <td><button className='btn' onClick={() => AddToShopList(prod.Id, 0, prod.ProductMkt)}>מחק מוצר</button></td>
                        </tr>
                    ))}
                </tbody></table>
                    <div>
                        <button className='btn' onClick={AddToCard}>הוספה לסל קניות</button>
                    </div>
                </div>
                    : <h2>אין פריטים ברשימה</h2>
                }
            </div>
                : <h1>אין רשימה להצגה</h1>
            }
        </div>
    )
}

export default UserShoppingListProduct;