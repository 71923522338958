import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form';
import Input from "../../containers/InputForm";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { StationEdit } from '../../../server/Station';

const schema = yup.object({
    Name: yup.string().required("שדה חובה"),
    numColumn: yup.number().positive().required(),
    numRow: yup.number().positive().required(),
    numCell: yup.number().positive().required(),
    StoreID: yup.number().positive().required(),
    BranchID: yup.number().positive().required(),
    PasswordComax: yup.string(),
    UserIdComax: yup.string(),
    PriceListID: yup.number().positive().required(),
}).required();



const Station = () => {
    const [stateId, setStateId] = useState(null)
    const setStaionInfo = (data) => {
        setStateId(data.Id)
        for (let item in data) {
            setValue(item, data[item])
        }
    }
    const editStation = (data) => {
        StationEdit(data)
            .then(x => setStaionInfo(x.data))
            .catch(() => console.log("error"))
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <div className='add-station'>
            <h1 className='title-role'>מחסן- הוספה</h1>
            <form onSubmit={handleSubmit(editStation)}>
                <h3>פרטי תחנה</h3>
                <div className='row'>
                    <Input errors={errors} label="שם התחנה" name="Name"
                        type="text" register={register} />
                    {!stateId ? <Fragment>
                        <Input errors={errors} label="מספר טורים" name="numColumn"
                            type="text" register={register} />
                        <Input errors={errors} label="מספר קומות" name="numRow"
                            type="text" register={register} />
                        <Input errors={errors} label="מספר תאים" name="numCell"
                            type="text" register={register} />
                    </Fragment> : null}
                </div>
                <div className='row'>
                    <Input errors={errors} label="קומקס StoreID" name="StoreID"
                        type="text" register={register} />
                    <Input errors={errors} label="קומקס BranchID" name="BranchID"
                        type="text" register={register} />
                    <Input errors={errors} label="קומקס PasswordComax" name="PasswordComax"
                        type="password" register={register} />
                    <Input errors={errors} label="קומקס UserIdComax" name="UserIdComax"
                        type="text" register={register} />
                    <Input errors={errors} label="קומקס PriceListID" name="PriceListID"
                        type="text" register={register} />
                </div>
                <button className='btn' type='submit'>עדכן</button>

            </form>
        </div>
    )
}

export default Station
