import React, { useState, useEffect } from 'react';
import { GetSalesList } from '../../../server/SalesManagement'
import { setPreload } from '../../../server/CartsOpen';
import showAlert from '../../../server/ShowAlert'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import SalePage from './SalePage';
import { globalFileIcon } from '../../../../axiosInstance';

const SalesManagement = () => {
    const dispatch = useDispatch();
    const [salesList, setSalesList] = useState([])

    useEffect(() => {
        getSalesList();
    }, [])

    const getSalesList = () => {
        dispatch(setPreload(true));
        GetSalesList()
            .then(x => {
                if (x.data) {
                    setSalesList(x.data)
                }
            })
            .catch(() => showAlert({}))
            .finally(() => dispatch(setPreload(false)));
    }


    return (
        <div className="show-sales">
            <h1 className='title-role'>ניהול מבצעים</h1>
            <div className='btn'><NavLink className="sale-item" to={"/sale-page"} state={{ saleData: null }}>הוספת מבצע חדש</NavLink></div>
            <div className='sale-item-list'>
                {salesList.map(item =>
                    <NavLink
                        key={item.Id}
                        to={"/sale-page"}
                        state={{ saleData: item }}
                        className='sale-item'
                        style={{
                            backgroundImage: `url(${globalFileIcon + 'addressBg.svg'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            backgroundColor: 'transparent',
                            width: '140px',
                            height: '130px',
                            margin: '10px'
                        }}
                    >
                        {item.Text}
                    </NavLink>
                )}
            </div>
        </div>
    )
}

export default SalesManagement