import React, { Fragment, useState } from "react";
import { globalFileServer } from "../../../axiosInstance";

const SearchProduct = ({ products, onSelectProduct }) => {
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      {/* // <div className="col-lg-12 title">*/}
      <div className="product-search">
        <img
          loading="lazy"
          className="search"
          src={globalFileServer + "iconcss/search.svg"}
          alt="search"
        />
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <div className="product-items">
          {search.length > 2
            ? products.map((element, index) =>
                element.ProductName && element.ProductName.includes(search) ? (
                  <div
                    key={index}
                    className="product-item"
                    onClick={() => {
                      onSelectProduct(element.Mkt);
                      setSearch("");
                    }}
                  >
                    <p>{element.ProductFullDescription}</p>
                  </div>
                ) : null
              )
            : null}
        </div>
      </div>
      {/* // </div>  */}
    </Fragment>
  );
};
export default SearchProduct;
