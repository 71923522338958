import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action'

export const GetStation = (id) => {
    return axios.get(`${url}Station/GetStation?id=${id}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const StationEdit = (data) => {
    return axios.post(`${url}Station/AddStations`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const GetStations = () => {
    return dispatch => {
        axios.get(`${url}/Station/GetStations`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(x => dispatch({ type: actionType.SET_STATIONS, payload: x.data }))
    }
}

export const getColumns = (stationId) => {
    return axios.get(`${url}LocationColumn/GetColumns?stationId=${stationId}&all=true`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const setRowsNew = (StationId, Quantity, LocationColumnId, LocationRowId) => {
    return axios.post(`${url}LocationColumn/AddNewRows`, { StationId, Quantity, LocationColumnId, LocationRowId }, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}
export const AddNewCells = (StationId, Quantity, LocationColumnId, LocationRowId) => {

    return axios.post(`${url}LocationColumn/AddNewCells`, { StationId, Quantity, LocationColumnId, LocationRowId }, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}
export const setColumnsNew = (StationId, Quantity) => {

    return axios.post(`${url}LocationColumn/AddNewColumns`, { StationId, Quantity }, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const getRows = (columnId) => {
    return axios.get(`${url}LocationColumn/GetRows?columnId=${columnId}&all=true`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const geCells = (rowId) => {
    return axios.get(`${url}LocationColumn/GetCells?rowId=${rowId}&all=true`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const saveColm = (data) => {
    return axios.post(`${url}LocationColumn/SaveColumnInfo`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}
export const saveCells = (data) => {
    return axios.post(`${url}LocationColumn/SaveCellInfo`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}
export const SaveRowInfo = (data) => {
    return axios.post(`${url}LocationColumn/SaveRowInfo`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    })
}

export const AddNewCell = (data) => {
    return axios.post(`${url}LocationColumn/AddNewCell`,data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

