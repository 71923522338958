import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { addProductToList } from "../../../server/ShoppingList"
import showAlert from '../../../server/ShowAlert';
import { setPreload } from '../../../server/CartsOpen';
import { NavLink } from "react-router-dom";

const ProductAddToShoppingList = ({ selectedProd, close }) => {
    const dispatch = useDispatch();
    const listNames = useSelector(state => state.shoppingList.shoppingList);
    const [chosenList, setChosenList] = useState(null)
    const [quantityAddToShopList, setQuantityAddToShopList] = useState("")


    const updateChosenName = (value) => {
        setChosenList(value)
    }
    const AddToShopList = () => {
        if (quantityAddToShopList === "" || chosenList === null) {
            showAlert({ msgTitle: "בחר שם רשימה וכמות להוספה", msgType: "error" })
            return
        }
        dispatch(setPreload(true));
        const val = {
            Quantity: quantityAddToShopList,
            ProductMkt: selectedProd.Mkt,
            CustomerListId: chosenList,
        }
        addProductToList(val)
            .then((x) => {
                if (x.data) {
                    showAlert({ msgTitle: "המוצר התווסף לרשימה", msgType: "success" })
                    close()
                }
                else {
                    showAlert({ msgTitle: "המוצר לא התווסף", msgType: "error" })
                }
            })
            .catch(() => showAlert())
            .finally(() => dispatch(setPreload(false)));
    }

    return (
        <div className="product-add-to-shopping-list-pop-up">
            {listNames.length > 0 ? <div>
                <div className='select-list'>
                    <p>בחר לאיזה רשימה להוסיף את ה{selectedProd.ProductName}</p>
                    <select
                        className='select-day-type'
                        onChange={(e) => updateChosenName(e.target.value)}
                        value={chosenList}
                    >
                        <option value="" disabled selected>בחר</option>
                        {listNames.map(item => <option
                            key={item.Id}
                            value={item.Id}
                        >{item.Name}
                        </option>
                        )}
                    </select>
                </div>
                <div className='quantity-to-add'>
                    <p>בחר כמות </p>
                    <input
                        type="number"
                        value={quantityAddToShopList}
                        onChange={(e) => setQuantityAddToShopList(e.target.value)}
                        onBlur={(e) => setQuantityAddToShopList(e.target.value)}
                        min="1"
                    />
                </div>
                <button className='btn' onClick={AddToShopList}>שמור</button>
            </div>
                : <h2 className='no-list'>אין רשימות</h2>}
            <NavLink className="open-list-page" to="/shopping-list">
                הוסף רשימת קניות חדשה &gt;
            </NavLink>
        </div>

    )
}

export default ProductAddToShoppingList;