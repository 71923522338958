import React, { useEffect, useState } from 'react';
import { getDateType, dateTypePost } from '../../../server/DeliveryTimeEdit'
import Input from '../../containers/Input';
import showAlert from '../../../server/ShowAlert'
import DeliveryTimeDayOfWeek from './DeliveryTimeDayOfWeek';
import DeliveryTimeSpecialDay from './DeliveryTimeSpecialDay';
import { useDispatch } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';

const DeliveryTimeDayTypes = () => {
    const dispatch = useDispatch();
    const [dayTypes, setDayTypes] = useState([]);

    useEffect(() => {
        GetDateType()
    }, [])

    const GetDateType = () => {
        getDateType()
            .then(x => {
                setDayTypes(x.data)
            })
            .catch(() => console.log("error"));
    }

    const HandleSetName = (value, id, key) => {
        const dayType = [...dayTypes];
        const index = dayType.findIndex(x => x.Id === id)
        const changeDayType = { ...dayType[index] }
        changeDayType[key] = value;
        changeDayType.haveChanges = true;
        dayType[index] = changeDayType;

        setDayTypes(dayType);

    }

    const saveChanges = (id) => {
        dispatch(setPreload(true));
        const dayType = [...dayTypes];
        const index = dayType.findIndex(x => x.Id === id)
        const changeDayType = { ...dayType[index] }

        dateTypePost(changeDayType)
            .then(x => {
                if (x.data) {
                    changeDayType.haveChanges = false
                    dayType[index] = changeDayType;
                    setDayTypes(dayType);
                    showAlert({ msgTitle: 'השינויים נשמרו בהצלחה.', msgType: 'success' })
                }
            })
            .catch(() => showAlert({ msgTitle: '  הייתה בעיה בשמירת הנתונים.', msgType: 'error' }))
            .finally(() => dispatch(setPreload(false)));
    }

    return (
        <div className="delivery-day-type">
            <div className='table-data'>
                <table>
                    <tbody>
                        <tr>
                            <th>סוג יום </th>
                            <th>שעת פתיחה </th>
                            <th>שעת סגירה </th>
                            <th></th>
                        </tr>
                        {dayTypes.length ? dayTypes.map(x => <tr key={x.Id}>
                            <td>
                                <Input
                                    type="text"
                                    Value={x.Name}
                                    className='input'
                                    InputChange={(value) => HandleSetName(value, x.Id, "Name")}
                                />
                            </td>
                            <td>
                                <Input
                                    type="text"
                                    Value={x.Start}
                                    className='input'
                                    InputChange={(value) => HandleSetName(value, x.Id, "Start")}
                                />
                            </td>
                            <td>
                                <Input
                                    type="text"
                                    Value={x.End}
                                    className='input'
                                    InputChange={(value) => HandleSetName(value, x.Id, "End")}
                                />
                            </td>
                            <td>
                                <button className='btn' onClick={() => saveChanges(x.Id)} disabled={!x.haveChanges}>
                                    שמור
                                </button>
                            </td>

                        </tr>
                        ) : null}
                    </tbody>
                </table >
            </div>
            <DeliveryTimeDayOfWeek dayTypes={dayTypes} setDayTypes={() => { setDayTypes([]) }} />
            <DeliveryTimeSpecialDay dayTypes={dayTypes} setDayTypes={() => { setDayTypes([]) }} />
        </div>
    )
}

export default DeliveryTimeDayTypes