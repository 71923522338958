import React, { useEffect, useState } from 'react';
import Address from './Address';
import DetailsWindow from '../containers/DetailsWindow';
import NewAddress from './NewAddress';
import { GetShippingAddress, ShippingAddressDelete, ShippingAddressMain } from '../../server/ShippingAddress'
import { useDispatch, useSelector } from 'react-redux';
import { setToggleCart } from '../../server/CartsOpen'
import { polygon, PriceDeliveryState } from '../../server/Polygon';

const AddressShipping = ({ setSelectedAddres, parent }) => {
    const [newAddress, setNewAddress] = useState(false);
    const [address, setAddress] = useState([]);
    const [polygonState, setPolygonState] = useState([]);

    const dispatch = useDispatch();
    const { user, role, stationId } = useSelector(state => ({
        user: state.user.user,
        role: state.user.role,
        stationId: state.user.stationId,
    }));

    useEffect(() => {
        if (role) {
            dispatch(setToggleCart(false));
        }
        if (user) {
            if (!polygonState || !polygonState.length) {
                polygon(stationId).then(x => setPolygonState(x.data.polygon))
            }
            GetShippingAddress(user.Id)
                .then(x => setAddress(x.data))
                .catch(() => console.log("error"));
        }
        if (parent === 'reset') {
            setNewAddress(true)
        }
    }, [user])

    useEffect(() => {
        if (address.length && polygonState.length) {
            const element = address.find(x => x.MainAddress)
            if (element) {
                if (setSelectedAddres) {
                    setSelectedAddres(element)
                }
                dispatch(PriceDeliveryState(element.lat, element.lng, polygonState))
            }
        }
    }, [address, polygonState])

    const pushAddress = (NewAddress, addressList) => {
        const addressCopy = [...addressList];
        if (NewAddress["MainAddress"] == 1) {
            addressCopy.map(x => x.MainAddress = 0)
        }

        const mainA = addressCopy.find(x => x.MainAddress === 1)
        //if there is none main address 
        if (!mainA) {
            NewAddress["MainAddress"] = 1
        }

        addressCopy.push(NewAddress);
        setAddress(addressCopy);
        setNewAddress(false)
    }

    const setActiveAddress = (element) => {
        const addressList = [...address];
        addressList.forEach(item => item.MainAddress = 0);
        addressList.find(x => x.Id === element.Id).MainAddress = 1;
        setAddress(addressList);
        ShippingAddressMain(element.Id, element.CustomerId)
    }

    const removeAddress = (Id) => {
        ShippingAddressDelete(user.Id, Id)
            .then(() => {
                const addressList = address.filter(item => item.Id !== Id);
                setAddress(addressList);
            })
            .catch(() => console.log("error"));

    }


    return (
        <div className="private-area">
        {parent !== 'reset' ? <section id='address'>
                <DetailsWindow title='כתובת למשלוח'>
                    {address ?
                        <Address address={address}
                            setActiveAddress={setActiveAddress}
                            removeAddress={removeAddress}
                            setNewAddress={setNewAddress} />
                        : null}
                </DetailsWindow>
            </section> : null}
            <section id='add-address'>
                {newAddress && polygonState && polygonState.length ?
                    <DetailsWindow title='הוספת כתובת'>
                        <NewAddress pushAddress={pushAddress} address={address} polygonState={polygonState} />
                    </DetailsWindow> : null}
            </section>
        </div>
    )

}
export default AddressShipping