import React from "react";
import ReactDOM from "react-dom/client";
import App from './js/App'
import { BrowserRouter } from "react-router-dom";
import store from "./js/store/index";
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
     <BrowserRouter>
      <App />
     </BrowserRouter>
   </Provider>
);
