import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";
import userReducer from './user'
import categoryReducer from './category'
import cardReducer from './card.reducer'

import productsInCartReducer from './productsInCart.reducer'
import displayProductReducer from "./displayProduct.reducer";
import shoppingListReducer from "./shoppingList.reducer";
import stationReducer from "./station.reducer";
import selectedProduct from './selectedPtoduct.reducer';
import parameters from './parameters.reducer'

const initialState = {};


const reducers = combineReducers({
  user: userReducer,
  category: categoryReducer,
  productsInCart: productsInCartReducer,
  displayProduct: displayProductReducer,
  openCart: cardReducer,
  shoppingList: shoppingListReducer,
  station: stationReducer,
  selectedProduct: selectedProduct,
  parameters: parameters
})


const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  applyMiddleware(...middleware)
);
export default store;