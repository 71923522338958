import React, { useEffect, useState } from 'react';
import { globalFileIcon } from '../../../../axiosInstance';
import Autosuggest from 'react-autosuggest';
import { getAllCustomer } from '../../../server/Customer'
import PopUp from '../../containers/PopUp'

const getSuggestionValue = suggestion => suggestion.name;
const renderSuggestion = suggestion => (
	<div className="hello"><span>{suggestion.Name} {suggestion.LastName} / </span><span>{suggestion.CustomerWebId}</span></div>
);

const Users = () => {
	const [userList, setUserList] = useState([])
	const [userListAll, setUserListAll] = useState([])
	const [suggestions, setSuggestions] = useState([])
	const [value, setValue] = useState('')
	const [toShow, setToShow] = useState(25)
	const [pCounter, setPCounter] = useState([])
	const [pFrom, setPFrom] = useState(0)
	const [pTo, setPTo] = useState(24)
	const [cFrom, setCFrom] = useState(0)
	const [cTo, setCTo] = useState(5)
	const [userInfo, setUserInfo] = useState(null)
	const [back, setBack] = useState(false);



	useEffect(() => {
		getUsers();
	}, [])

	const onChange = (event, { newValue }) => {
		setValue(newValue);
	}
	const getSuggestions = ({ value }) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const data = !inputValue.length ? [] : userList.filter(user =>
			user.Phone.includes(inputValue) ||
			(user.Phone2 && user.Phone2.includes(inputValue)) ||
			(user.CustomerWebId && user.CustomerWebId.toString().includes(inputValue)) ||
			user.Email.includes(inputValue) ||
			user.Name.toLowerCase().slice(0, inputLength) === inputValue ||
			inputValue.toLowerCase().includes(user.Name.toLowerCase()) ||
			(user.LastName && inputValue.toLowerCase().includes(user.LastName.toLowerCase()))
		);
		// const data = !inputValue.length ? [] : userList.filter(user =>
		// 	user.Passport.toLowerCase().slice(0, inputLength) === inputValue ||
		// 	inputValue.toLowerCase().includes(user.Name.toLowerCase()) ||
		// 	(user.LastName && inputValue.toLowerCase().includes(user.LastName.toLowerCase()))
		// );
		setSuggestions(data)
	}

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
		setValue("");
	}
	const onSuggestionSelected = (suggestion, method) => {
		if (method.method === "click" || method.method === "enter") {
			const userList1 = userList.filter(elem => elem.Id === method.suggestion.Id);
			setUserList(userList1)
			setBack(true);
		}
	}
	const updateUserList = () => {
		if (back) { setBack(false); }
		resetCounter();
		setUserList(userListAll)
		setPCounter(Array.from(Array(Math.ceil(userListAll.length / toShow)).keys()));

	}

	const getUsers = () => {
		getAllCustomer()
			.then(x => {
				setUserList(x.data)
				setUserListAll(x.data)
				setPCounter(Array.from(Array(Math.ceil(x.data.length / toShow)).keys()))
			})
			.catch(() => console.log("error"));
	}

	const showPaggination = (number) => {
		let val = number * toShow;
		setCFrom(number)
		setCTo(number + 5)
		setPFrom(val)
		setPTo(val + toShow)
		window.scrollTo(0, 0);
	}

	const UpPaggination = () => {
		setCFrom(cFrom + 1)
		setCTo(cTo + 1)
		setPFrom(pFrom + toShow)
		setPTo(pTo + toShow)
		window.scrollTo(0, 0);
	}

	const downPaggination = () => {
		setCFrom(cFrom - 1)
		setCTo(cTo - 1)
		setPFrom(pFrom - toShow)
		setPTo(pTo - toShow)
		window.scrollTo(0, 0);
	}

	const toShowFun = (num) => {
		let pCounter = Array.from(Array(Math.ceil(userList.length / num)).keys());
		setToShow(num)
		setPCounter(pCounter);
		setPFrom(0);
		setPTo(num);
		setCFrom(0);
		setCTo(5)
		window.scrollTo(0, 0);
	}
	const resetCounter = () => {
		setToShow(25);
		setPFrom(0);
		setPTo(24);
		setCFrom(0);
		setCTo(5)
	}



	return (
		<div className="users">
			<h1 className='title-role'>לקוחות</h1>
			<div className="users-header">
				{back ?
					<span className='arrow-side'><img loading="lazy" onClick={updateUserList} src={globalFileIcon + 'arrowSide.svg'} alt="arrowSide" /></span>
					:
					<p>{'נמצאו ' + userList.length + ' לקוחות'}</p>
				}
				{!back ?
					<div className="search">
						<Autosuggest
							suggestions={suggestions}
							onSuggestionsFetchRequested={getSuggestions}
							onSuggestionSelected={onSuggestionSelected}
							onSuggestionsClearRequested={onSuggestionsClearRequested}
							getSuggestionValue={getSuggestionValue}
							renderSuggestion={renderSuggestion}
							inputProps={
								{
									placeholder: 'חפש לקוח על פי שם',
									value,
									onChange: onChange
								}}
							highlightFirstSuggestion={true}

						/>
						<img loading="lazy" className="placeholder" src={globalFileIcon + 'search.svg'} alt="search" />

					</div>
					: null}
			</div>
			<table className='users-table'>
				<tbody>
					<tr>
						<th>שם</th>
						<th>מס</th>
						<th>מייל</th>
						<th>טלפון</th>
						<th>פרטים</th>
					</tr>
					{userList.length ? userList.map((element, index) => index >= pFrom && index < pTo ? (<tr key={element.Id}>
						<td key={element.Id}>
							<p>{element.LastName} {element.Name}</p>
						</td>
						<td><p>{element.CustomerWebId}</p></td>
						<td>	<p>{element.Email}</p></td>
						<td>	<p>{element.Phone}</p></td>
						<td>
							<img loading="lazy" onClick={() => setUserInfo(element.Id)} src={globalFileIcon + 'info.svg'} alt="info" />
							{userInfo === element.Id ?
								<PopUp width='500px' close={() => setUserInfo(null)}>
									<div className='user-info'>
										{element.FirstName ? <div>
											<span>שם הלקוח:</span>
											<span>{element.Name} {element.LastName}</span>
										</div> : null}

										{element.CustomerWebId ? <div>
											<span>מס' פנימי:</span>
											<span>{element.CustomerWebId}</span>
										</div> : null}
										{element.comaxId ? <div>
											<span>מס' חיצוני:</span>
											<span>{element.comaxId}</span>
										</div> : null}
										{element.Email ? <div>
											<span>דואר_אלקטרוני:</span>
											<span>{element.Email}</span>
										</div> : null}
										{element.Phone ? <div>
											<span>מספר_טלפון:</span>
											<span>{element.Phone}</span>
										</div> : null}
										{element.Phone2 ? <div>
											<span>מספר נוסף:</span>
											<span>{element.Phone2}</span>
										</div> : null}
									</div>
								</PopUp>
								: null}
						</td>
					</tr>) : null) : null}
				</tbody>
			</table >
			{userList.length > toShow ?
				<div className="pagination">
					<ul>
						<li
							onClick={() => toShowFun(25)}
							className={toShow === 25 ? 'active' : null}
						>
							<span>25</span>
						</li>
						<li
							onClick={() => toShowFun(50)}
							className={toShow === 50 ? 'active' : null}
						>
							<span>50</span>
						</li>
						<li
							onClick={() => toShowFun(100)}
							className={toShow === 100 ? 'active' : null}
						>
							<span>100</span>
						</li>
					</ul>
					<ul>
						{cFrom > 0 ? <li onClick={downPaggination}><span>{"<"}</span></li> : null}
						{pCounter.length ? pCounter.map((element, index) => index >= cFrom - 2 && index < cTo - 2 ? (
							<li
								key={index}
								onClick={() => cFrom !== index ? showPaggination(element) : null}
								className={cFrom === index ? 'active' : null}
							>
								<span>{element + 1}</span>
							</li>
						) : null
						) : null}
						{cTo < pCounter.length ? <li onClick={UpPaggination}><span>{">"}</span></li> : null}
					</ul>
					<ul>

					</ul>
				</div>
				: null}
		</div>
	)
}

export default Users