import React, { Fragment, memo } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'




const CategoryHeaderPage = memo(({ id, FatherId, subId }) => {


    const categories = useSelector(state => state.category.categories);
    if (FatherId === null) {
        FatherId = subId;
        subId = id
    }
    const level2 = categories.filter(item => item.FatherId === FatherId);
    const level3 = categories.filter(item => item.FatherId === subId)
    return (
        <Fragment>
            <div className="cat-wrapper-main">
                <div className="categories">
                    {level2 && level2.length ? level2.map((element) => {
                        return (
                            <div className="head-cat-cont" key={element.Id}>
                                <NavLink to={'/category/' + FatherId + '/' + element.Id}>
                                    <p className={id === element.Id ? "cat-active" : null}> {element.Name}</p>
                                </NavLink>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
            <div className="cat-wrapper-main">
                <div className="categories">
                    {level3 && level3.length ? level3.map(element => {
                        return (
                            <div className="head-cat-cont" key={element.Id}>
                                <NavLink to={'/category/' + FatherId + '/' + subId + '/' + element.Id}>
                                    <p className={subId === element.Id ? "cat-active" : null}>{element.Name}</p>
                                </NavLink>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        </Fragment>
    )
})

export default CategoryHeaderPage
