import React, { useState, useEffect } from 'react';
import { AddNewSalesPost } from '../../../server/SalesManagement'
import Input from '../../containers/InputForm';
import { setPreload } from '../../../server/CartsOpen';
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import StationsInSale from './StationsInSale'
import showAlert from '../../../server/ShowAlert'
import { useLocation } from 'react-router-dom'
import TimeYearMonthDate from '../../containers/Date'
import ProductInSale from './ProductInSale';
import { NavLink } from 'react-router-dom'
import { globalFileIcon } from '../../../../axiosInstance';

const schema = yup.object({
    Text: yup.string().required("כותרת חובה").test('len', "אורך מקסימלי 50", x => x.length <= 50),
    // TypeId: yup.string().required("חובה להכניס סוג הנחה"),
    QuantityFrom: yup.number().required("חובה להכניס כמות החל מ ").positive("חייב להיות מספר גדול מ 0").test("maxDigits", "עד 2 ספרות אחרי הנקודה", (number) => Number.isInteger(number * (10 ** 2))),
    QuantityMin: yup.number().required('חובה להכניס כמות מינימאלית').positive("חייב להיות מספר גדול מ 0").test("maxDigits", "עד 2 ספרות אחרי הנקודה", (number) => Number.isInteger(number * (10 ** 2))),
    FromDate: yup.string().required("חובה להכניס מתאריך "),
    ToDate: yup.string().required("חובה להכניס עד תאריך "),
    Duplicates: yup.number().required("חובה להכניס מספר כפולות ").positive("חייב להיות מספר גדול מ 0").test("maxDigits", "עד 2 ספרות אחרי הנקודה", (number) => Number.isInteger(number * (10 ** 2))),
    Realizations: yup.number().required("חובה להכניס מספר מימושים ללקוח ").positive("חייב להיות מספר גדול מ 0").integer("חייב להיות מספר שלם"),
    Price: yup.number().required("חובה להכניס מחיר ").test("maxDigits", "עד 2 ספרות אחרי הנקודה", (number) => number >= 0 && Number.isInteger(number * (10 ** 2))),
}).required();



const SalePage = () => {
    const { state: { saleData } } = useLocation();
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, getValues, formState: { errors, dirtyFields }, reset } = useForm({
        defaultValues: { ...saleData },
        resolver: yupResolver(schema)
    });
    const [sale, setSale] = useState(saleData)
    const checkedDates = (FromDate, ToDate) => {
        const toDay = new Date()
        if (new Date(FromDate) >= new Date(ToDate)) {
            showAlert({ msgTitle: 'תאריך התחלה חייב להיות לפני תאריך סיום.', msgType: 'error' })
            return false

        }
        if (new Date(toDay) > new Date(FromDate)) {
            showAlert({ msgTitle: 'לא ניתן להוסיף/לערוך מבצע שכבר התחיל.', msgType: 'error' })
            return false
        }
        return true
    }

    const save = (data) => {
        const correctDate = checkedDates(data.FromDate, data.ToDate)
        if (correctDate) {
            data.FromDate = (data.FromDate)
            data.ToDate = new Date(data.ToDate)
            dispatch(setPreload(true));
            AddNewSalesPost(data)
                .then(x => {
                    if (x.data) {

                        setSale(x.data)
                        reset(undefined, {
                            dirtyFields: false, // dirtyFields will be reset
                            errors: true, // anything with true will not be reset
                        });
                        for (let item in x.data) {
                            setValue(item, x.data[item])
                        }
                        showAlert({ msgTitle: "המבצע נשמר בהצלחה", msgType: 'success' })
                    }
                })
                .catch((x) => {
                    if (x.status == 400) {
                        showAlert({ msgTitle: x.data })
                    }
                    else {
                        showAlert({})
                    }
                })
                .finally(() => dispatch(setPreload(false)));
        }
        else {
            showAlert({ msgTitle: "המבצע כבר התחיל, לא ניתן לשנות הגדרות", msgType: 'success' })
            return
        }
    }

    return (
        <div className="add-new-sale">
            <h1 className='title-role'>פרטי מבצע</h1>
            <form className="register" onSubmit={handleSubmit(save)}>
                <div className="sale-form">
                    <div>
                        <Input
                            type="text"
                            register={register}
                            label="כותרת"
                            errors={errors}
                            name="Text"
                            dirtyFields={dirtyFields}
                        />

                        {/* <Input
                                    type="number"
                                    register={register}
                                    label="סוג מבצע"
                                    errors={errors}
                                    name="TypeId" /> */}

                        <Input
                            type="number"
                            register={register}
                            label="החל מ-  "
                            errors={errors}
                            name="QuantityFrom"
                            dirtyFields={dirtyFields} />

                        <Input
                            type="number"
                            register={register}
                            label="כמות מינימלית"
                            errors={errors}
                            name="QuantityMin"
                            dirtyFields={dirtyFields}
                        />

                        <Input
                            type="date"
                            register={register}
                            label="מתאריך"
                            errors={errors}
                            name="FromDate"
                            dirtyFields={dirtyFields}
                            value={TimeYearMonthDate(getValues('FromDate'))}
                        />
                        <Input
                            type="date"
                            register={register}
                            label="עד תאריך"
                            errors={errors}
                            name="ToDate"
                            dirtyFields={dirtyFields}
                            value={TimeYearMonthDate(getValues('ToDate'))}

                        />

                        <Input
                            type="number"
                            register={register}
                            label="כפולות"
                            errors={errors}
                            name="Duplicates"
                            dirtyFields={dirtyFields}
                        />

                        <Input
                            type="number"
                            register={register}
                            label="מימושים"
                            errors={errors}
                            name="Realizations"
                            dirtyFields={dirtyFields}
                        />

                        <Input
                            type="text"
                            register={register}
                            label="מחיר"
                            errors={errors}
                            name="Price"
                            dirtyFields={dirtyFields}
                        />
                        <input className='btn' type="submit" value="שמור" />
                    </div>
                </div>
            </form>
            {sale ?
                <div className='station-and-product-in-sale'>
                    <StationsInSale sale={sale} checkedDates={checkedDates} />
                    <ProductInSale sale={sale} checkedDates={checkedDates} />
                </div> : null}
            <NavLink className="go-back-to-sale-list" to={"/sales-management"} ><img  loading="lazy"   loading="lazy"  src={globalFileIcon + 'arrowSide.svg'} alt="arrowSide" /></NavLink>

        </div>
    )

}
export default SalePage