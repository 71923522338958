import React, { useState } from 'react';
import { globalFileIcon } from '../../../../axiosInstance'
import Input from '../../containers/Input';
import { getInventoryPerLocation } from '../../../server/InventoryUpdate';
import { isANumber } from '../../../ValidFunction'
import { useSelector } from 'react-redux/es/exports';

const ProductDetailsPopUp = ({ productMkt, AddPlace, CloseManualLocation }) => {
	const [inputColumn, setInputColumn] = useState("");
	const [inputBox, setInputBox] = useState("");
	const [inputFloor, setInputFloor] = useState("");
	const [dataInBox, setDataInBox] = useState("");
	const [locationExist, setLocationExist] = useState(false);
	const [locationNotExist, setLocationNotExist] = useState(false);
	const [fullLocationName, setFullLocationName] = useState("");

	const stationId = useSelector(state => state.user.stationId)


	const CheckAllDataInInput = (value) => {
		if (value.key === 'Enter' && inputColumn && inputBox && inputFloor) {
			setDataInBox("")
			//לקרוא לפונקציה שאומרת מה יש במיקום הזה
			getInventoryPerLocation(inputColumn, inputFloor, inputBox, stationId)
				.then(x => {
					if (x.data) {
						//בדיקה אם המוצר קיים כבר במיקום הזה
						if (x.data.products.find(prod => prod.Id === productMkt)) {
							setLocationExist(true)
						}
						else {
							if (x.data.products.length) {
								setDataInBox(x.data.products);
								setFullLocationName(x.data.inventory)
							}
							else {
								AddPlace(x.data.inventory)
								CloseManualLocation()
							}
						}
					}
					else {
						setLocationNotExist(true)
					}
				})
				.catch(() => console.log("error"))
			// }
		}
	}


	return (
		<div className="manual-location">
			<div className='close-btn' onClick={CloseManualLocation}>
				<img loading="lazy" src={globalFileIcon + 'close-menu.svg'} alt="close" />
			</div>
			<div className='all-inputs' onKeyDown={(e) => CheckAllDataInInput(e)}>
				<Input
					Title="טור"
					Type="number"
					Value={inputColumn}
					InputChange={(value) => isANumber(value) ? setInputColumn(value) : null}
				/>
				<Input
					Title="ארגז"
					Type="number"
					Value={inputBox}
					InputChange={(value) => isANumber(value) ? setInputBox(value) : null}
				/>
				<Input
					Title="קומה"
					Type="number"
					Value={inputFloor}
					InputChange={(value) => isANumber(value) ? setInputFloor(value) : null}
				/>
			</div>
			{dataInBox.length ?
				<div className="product-in-box">
					<h4> לתשומת ליבך, במיקום הנבחר קיימים המוצרים הבאים:</h4>
					<ul>
						{dataInBox.map(item => <li key={item.Id}>{item.Name}</li>)}
					</ul>
				</div>
				: null}
			{locationExist ?
				<h4> לתשומת ליבך, המוצר קיים במיקום זה</h4>
				: null}
			{locationNotExist ?
				<h4> לתשומת ליבך, המיקום לא קיים</h4>
				: null}
			<div className='save-btn'>
				{fullLocationName ?
					<button
						onClick={() => AddPlace(fullLocationName)}
					>שמור</button> : null}
			</div>
		</div>
	)
}

export default ProductDetailsPopUp