import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from '../../containers/Select';
const SelectCategory = ({ setSelect }) => {

    const categories = useSelector(state => state.category.categories)
    const [selectCategory1, setSelectCategory1] = useState('')
    const [selectCategory2, setSelectCategory2] = useState('')

    const [selectCategory, setSelectCategory] = useState('')

    const select = () => {
        const url = [selectCategory1, selectCategory2, selectCategory];
        const selectCategoryId = selectCategory ? selectCategory : selectCategory2 ? selectCategory2 : selectCategory1
       
        const data = {
            url: url.join('/'),
            name: categories.find(x => x.Id === selectCategoryId).Name,
            selectCategory:selectCategoryId
        }
        setSelect(data)
    }
    const SetSelectCategory1 = (value) => {
        setSelectCategory1(parseInt(value))
        setSelectCategory2(null)
        setSelectCategory(null)
    }
    const SetSelectCategory2 = (value) => {
        setSelectCategory2(parseInt(value))
        setSelectCategory(null)
    }

    return (
        <div className='select-category'>
            <Select Title="בחר מחלקה" Name="subId" Value={selectCategory1} Options={categories.filter(x => !x.FatherId && x.Active)}
                SelectChosen={(value) => SetSelectCategory1(parseInt(value))}
            />

            {selectCategory1 ? <Select Title="בחר קבוצה" Value={selectCategory2} Name="subId" Options={categories.filter(x => x.FatherId === selectCategory1 && x.Active)}
                SelectChosen={(value) => SetSelectCategory2(value)}
            /> : null}

            {selectCategory2 ? <Select Title="בחר תת קבוצה" Value={selectCategory} Name="subId" Options={categories.filter(x => x.FatherId === selectCategory2 && x.Active)}
                SelectChosen={(value) => setSelectCategory(parseInt(value))}
            /> : null}

            {selectCategory1 ? <button className='btn' type='button' onClick={select}> בחר</button> : null}
        </div>
    )
}

export default SelectCategory
