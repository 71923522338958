import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form';
import Input from "../../containers/InputForm";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const schema = yup.object({
    PointName: yup.string().required("שדה חובה"),
    Price: yup.number().positive().required(),

}).required();




const AddPoint = ({ savePoint, point, deletePoint }) => {

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { ...point }
    });
    const saveAndResetPoint = (date) => {
        savePoint(date);
        if (point) {
            reset();
        }
    }
    useEffect(() => {
        for (let item in point) {
            setValue(item, point[item])
        }
    }, [point])

    return (
        <form className='add-point' onSubmit={handleSubmit(saveAndResetPoint)}>
            {!point ? <h3>הוספת מיקום חדש</h3> : null}
            <div className='flex-container'>
                <Input
                    errors={errors}
                    label="כינוי למיקום"
                    name="PointName"
                    type="text"
                    register={register} />
                <Input
                    errors={errors}
                    label="מחיר"
                    name="Price"
                    type="text"
                    register={register}
                />
                {point ? <button type='button' className='btn' onClick={() => deletePoint(point)}>מחק</button> : null}

                <button className='btn' type='submit'> {!point ? "הוסף" : "עדכן"}</button>
            </div>
        </form>
    )
}

export default AddPoint
