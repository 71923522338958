import GoogleMapReact from 'google-map-react';
import { globalFileIcon } from '../../../../axiosInstance';

const AnyReactComponent = ({ text, lat, lng, deletePoint }) => {
    return (
        <div className='marker-data' type="button"
            lat={lat ? lat : 31.772561767303255}
            lng={lng ? lng : 35.16862111683302}
            style={{ border: "none" }} >
            <img  loading="lazy" src={globalFileIcon + 'marker.png'} alt="marker" onClick={()=>deletePoint()} />
            {text}
        </div>)
}

const MapWithAMarkerClustered = ({ markers, setAddMarks, deleteMarker, centerPlace: { lat, lng } }) => {

    return (
        <div style={{ height: '40vh', width: '50%' }}>
            <GoogleMapReact
                className='google-map'
                defaultZoom={16}
                defaultCenter={{ lat, lng }}
                onClick={({ lat, lng }) => setAddMarks({ lat, lng })} >
                {
                    markers.map(marker => <AnyReactComponent key={marker.Id ? marker.Id : 0} text={marker.PointName}
                        deletePoint={() => deleteMarker(marker)}
                        title={marker.PointName} lat={marker.lat} lng={marker.lng} />)
                }
            </GoogleMapReact >
        </div>

    );
}

export default MapWithAMarkerClustered;