import * as actionType from './action'

const initialState = {
    toggleMenu: false,
    toggleSidebar: false,
    openCart: false,
    preload: false

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.TOGGLE_MENU:
            return { ...state, toggleMenu: !state.toggleMenu }
        case actionType.TOGGLE_CART:
            return { ...state, openCart: action.payload }
        case actionType.TOGGLE_SIDEBAR:
            return { ...state, toggleSidebar: action.payload };
        case actionType.SET_PRELOAD:
            return { ...state, preload: action.payload };

        default:
            return state
    }
}
export default reducer;