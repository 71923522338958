export const isANumber=(str)=> {
    return /^\d+$/.test(str) || str === "";
}
export const isValidPrice=(str)=> {
const strSplit=str.split('.');
    return strSplit.length<=2&&(!strSplit[0]||(isANumber(strSplit[0])&&(!strSplit[1]||(isANumber(strSplit[1])&&strSplit[1].length<=2))))
}

export const validPhoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})||^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
// /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const validPassword=/^(?=.*[a-zA-Z])(?=.*[0-9.,-]).{8,15}$/;

export const IsValidLength=(string,min,max)=> {
    
let valid=true;
valid=valid&&(!min||string.length>=min);  
valid=valid&&(!max||string.length<=max); 
return valid; 
}