import React from "react";
import { globalFileIcon } from '../../../axiosInstance';
import { setFilterSort } from '../../server/Product';
import { useDispatch, useSelector } from 'react-redux'

const HeaderFilterAndSort = () => {
  const dispatch = useDispatch();
  const filterAndSort = useSelector(state => state.displayProduct.filterAndSort);

  return (
    <span className='filter-and-sort' onClick={() => dispatch(setFilterSort(!filterAndSort))}>
      <img  loading="lazy" src={globalFileIcon + 'filter-and-sorting.svg'} alt="filter-and-sorting" />
      <span>מיון וסינון</span>
    </span>
  )
}

export default HeaderFilterAndSort
