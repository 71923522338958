import React, { Fragment, useEffect } from 'react';
import showAlert from '../../../server/ShowAlert';
import { BaseUrl, tranzilaMasof } from '../../../../axiosInstance'
import { useSelector } from 'react-redux'


const PyaTranzila = ({ save, orderId, total, tranmode, ClosePayPopup, numPayments,numPaymentSelect }) => {

    const user = useSelector(state => state.user.user);
    useEffect(() => {
        const receivingMessages = (e) => {
            if (e.data && e.data.res === 'SuccessVerifon') {
                showAlert({ msgTitle: 'העסקה בוצע בהצלחה', msgType: 'success' })
                ClosePayPopup(true, e.data.PaymentTranzilaTokenId,numPaymentSelect);
            }
            if (e.data === 'ErrorVerifon') {
                showAlert({ msgTitle: 'העסקה נכשלה', msgText: 'אנא בדקו את פרטי הכרטיס או נסו כרטיס אחר', msgType: 'error' })
                ClosePayPopup(false);
            }

        }

        window.addEventListener('message', receivingMessages, true);
        return () => {
            window.removeEventListener('message', receivingMessages, true);
        }
    }, [])



    const typePay = numPayments > 1 && !tranmode ? 8 : 1;

    return (
        <Fragment>


            <iframe
                title='pay'
                src={
                    `https://direct.tranzila.com/${tranzilaMasof}/iframenew.php?currency=1&cred_type=${typePay}&lang=il${tranmode ? "&tranmode=VK" : ""}&buttonLabel=`
                    + `לתשלום&success_url_address=${BaseUrl}TranzilaPay${tranmode ? "" : "Now"}&fail_url_address=${BaseUrl}TranzilaPay${tranmode ? "" : "Now"}Failed`
                    + `&maxpay=${numPayments}&sum=${tranmode ? 1 : total}&CustomerId=${user.Id}`
                    + `&hidesum=1&nologo=1&HaveSave=${save}&OrderId=${orderId}`
                }>
                <p>Your browser does not support iframes.</p>
            </iframe>
        </Fragment>
    )
}

export default PyaTranzila
