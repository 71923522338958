import React, { useState } from 'react';
import './addCategoryDialog.scss';

const AddCategoryDialog = ({ handleAdding, handleClose, categoryLevel}) => {
  const [name, setName] = useState("");

  return (
    <div className="add-category-dialog-overlay">
      <div className="add-category-dialog-box">
        <span className="close-icon" onClick={()=>handleClose(false)}>×</span>
        <h2>הוסף מחלקה חדשה</h2>
        <p>הזן שם למחלקה החדשה שברצונך להוסיף.</p>
        <form onSubmit={(e) => {
            e.preventDefault();
            handleAdding(name);
        }}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="שם"
          />
          <button type="submit">הוסף</button>
        </form>
      </div>
    </div>
  );
};

export default AddCategoryDialog;
