import React, { useState, useEffect } from 'react';
import { setPreload } from '../../../server/CartsOpen';
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form';
// import {AddNewStationToSalePost} from '../../../server/SalesManagement'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Input from '../../containers/InputForm';
import showAlert from '../../../server/ShowAlert'


const schema = yup.object({
    StationId: yup.string().required("מזהה מחסן חובה"),
    Quantity: yup.string().required("חובה להכניס כמות "),
}).required();


const AddStationToSale = ({ updateStation }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const addStation = (data) => {
        updateStation(data, true)
    }

    return (
        <div className="add-new-station">
            <form className="register" onSubmit={handleSubmit(addStation)}>
                <Input
                    type="number"
                    register={register}
                    label="מזהה תחנה"
                    errors={errors}
                    name="StationId" />
                <Input
                    type="number"
                    register={register}
                    label="כמות"
                    errors={errors}
                    name="Quantity" />
                <input className='btn' type="submit" value="הוסף תחנה" />
            </form>
        </div>
    )
}

export default AddStationToSale